<ng-template #specUp let-modal>
	<div class="modal-header">
		<h3> Upload New Specials </h3>
	</div>
	<div class="modal-body text-dark">
		<ng-container *ngIf="!uploaddata.rows">
			<div class="row text-center w-100 ml-auto mr-auto">
				<ngx-dropzone [multiple]="false" (change)="onSelect($event)">
					<ngx-dropzone-label class="m-2">
						DROP FILE HERE OR CLICK TO UPLOAD
					</ngx-dropzone-label>
					<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
						<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
					</ngx-dropzone-preview>
				</ngx-dropzone>
			</div>
		</ng-container>
		<ng-container *ngIf="uploaddata.rows">
			<div class="row text-center w-100 ml-auto mr-auto">
				<button mat-stroked-button color="success" aria-label="" (click)="sendSpecials()" class="mt-2 mb-2">
					<mat-icon>cloud_upload</mat-icon> Update
				</button>
				<table class="table table-sm table-responsive table-fluid">
					<tr>
						<th *ngFor="let col of uploaddata.header">
							{{col}}
						</th>
					</tr>
					<tr>
						<th *ngFor="let col of uploaddata.header">
							<mat-select [(ngModel)]="mapping[col]">
								<mat-option *ngFor="let map of mapcols" [value]="map.field">
									{{ map.label }}
								</mat-option>
							</mat-select>
						</th>
					</tr>
					<tr *ngFor="let row of uploaddata.rows">
						<td *ngFor="let col of uploaddata.header">{{row[col]}}</td>
					</tr>
				</table>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #specEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<h4 class="title" *ngIf="itemdetails">{{itemdetails.item.stockid }}{{ itemdetails.item.description }}</h4>
		<form [formGroup]="specialForm" class="text-center">
					<div class="row">
						<div class="col-6">
							<mat-form-field appearance="outline">
								<mat-label>Part Number</mat-label>

								<input (change)="autocompleteFilter()" (keyup.enter)="$event.preventDefault();" (input)="autocompleteFilter()" type="text" class="text-left " matInput formControlName="stockid" [matAutocomplete]="stocksuggest" />
								<mat-autocomplete autoActiveFirstOption #stocksuggest="matAutocomplete">
									<mat-option *ngFor="let item of filteredOptions" [value]="item.stockid" (click)="selectItem(item.stockid)">
										{{item.stockid }} {{ item.description}}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field>
						</div>
						<div class="col-6">
							<mat-form-field appearance="outline">
								<mat-label>Qty Limit</mat-label>
								<input type="text" class="text-right" matInput formControlName="qtylimit" />
								<span matSuffix class="text-danger">&nbsp;&nbsp;&nbsp;QOH {{ itemdetails.total_qoh }} </span>
							</mat-form-field>

						</div>
						<div class="col-12 mt-3v">
							<mat-slide-toggle formControlName="nfd">
									<mat-label>No Additional Discounts</mat-label>
								</mat-slide-toggle>
							<span *ngIf="editing">
								<mat-slide-toggle formControlName="clearsold">
									<mat-label>Clear Qty Sold</mat-label>
								</mat-slide-toggle>
								&nbsp;&nbsp;&nbsp;
							</span>
							<mat-radio-group aria-label="AND QTY LIMIT" formControlName="andor" class="text-center" (change)="setValiditiy()">
								<mat-radio-button value="1">DATE LIMIT</mat-radio-button>
								&nbsp;&nbsp;&nbsp;
								<mat-radio-button value="2">QTY LIMIT</mat-radio-button>
							</mat-radio-group>
						</div>
						<div class="col-2">
						
						</div>
						<div class="col-3">
							<mat-form-field appearance="outline">
								<mat-label>Start Date</mat-label>
								<input matInput [matDatepicker]="picker1" formControlName="startdate" />
								<mat-datepicker-toggle matSuffix [for]="picker1">
								</mat-datepicker-toggle>
								<mat-datepicker #picker1></mat-datepicker>
							</mat-form-field>
						</div>
						<div class="col-2">
						</div>
						<div class="col-3">
							<mat-form-field appearance="outline">
								<mat-label>End Date</mat-label>
								<input matInput [matDatepicker]="picker2" formControlName="enddate" />
								<mat-datepicker-toggle matSuffix [for]="picker2">
								</mat-datepicker-toggle>
								<mat-datepicker #picker2></mat-datepicker>
							</mat-form-field>
						</div>
						<div class="col-2">
						</div>
					</div>
					<div class="row">
						<div class="col-4 ml-auto mr-auto">
							<mat-form-field appearance="outline">
								<span matPrefix>$&nbsp;&nbsp;</span>
								<mat-label>Blanket Price</mat-label>
								<input type="text" class="text-right " matInput [formControl]="blanket"/>
							</mat-form-field>
						</div>
					</div>

					<div class="row">

						<div class="col-auto" *ngFor="let x of prices_array;let i = index">
							<mat-form-field appearance="outline">
								<mat-label>{{ x.sales_type }}</mat-label>
								<input type="text" class="text-right " matInput [(value)]="x.price" (input)="x.price = $event.target.value;"/>
								<!-- <mat-hint *ngIf="editing"> {{ original_prices_array[i].price }} </mat-hint> -->
							</mat-form-field>
						</div>
					</div>
					<button mat-stroked-button color="success" aria-label="" (click)="saveSpecial()" class="mt-2">
						<mat-icon>save</mat-icon> Save
					</button>
				</form>


	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-container>
	<div class="main-content">
		<div class="container-fluid">
			<div class="row ">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}-3">
							<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Special Pricing</h4>
							<p class="card-category"></p>
						</div>
						<div class="card-body">
							<button mat-stroked-button color="success" aria-label="" (click)="$event.preventDefault();addSpecial()" class="mt-2 mb-2">
								<mat-icon>add</mat-icon> Add Special
							</button>
							&nbsp;&nbsp;&nbsp;
							<button mat-stroked-button color="success" aria-label="" (click)="$event.preventDefault();uploadSpecial()" class="mt-2 mb-2">
								<mat-icon>cloud_upload</mat-icon> Upload Specials
							</button>
							&nbsp;&nbsp;&nbsp;
							<button class="btn btn-sm btn-success" (click)="$event.preventDefault();exportSpecials()" >
								<i class="fa fa-file-excel-o"></i>
							</button>

							<div class="row">
								<div class="col-6 text-right">
									<mat-form-field appearance="outline">
										<mat-label>Search Specials</mat-label>
										<input type="text" class="text-left " matInput [formControl]="searchSpecials" (input)="itemSearch()" />
									</mat-form-field>
								</div>
							</div>
							<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
								<mat-tab label="Current">
									<div class="table">
										<table class="table table-bordered table-fixed table-repsonsive table-fluid w-100">
											<thead>
												<tr>
													<th></th>
													<th>Stock ID</th>
													<th class="max-td">Description</th>
													<th>Start Date<br>End Date</th>
													<th class="text-center">Remaning</th>
													<th class="text-center">QOH</th>
													<th class="text-center">Age</th>
													<th class="text-right max-td-small" *ngFor="let x of pricelvs">{{x.sales_type}}</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let p of active">
													<td>
														<button mat-icon-button color="danger" aria-label="" (click)="removeSpecial(p)" class="mt-2">
															<mat-icon>remove</mat-icon>
														</button>
														<button mat-icon-button color="success" aria-label="" (click)="editSpecial(p)" class="mt-2">
															<mat-icon>edit</mat-icon>
														</button>
													</td>
													<td>{{ p.stockid }}</td>
													<td class="max-td">{{ p.description }}</td>
													<td>{{ p.startdate | date: 'shortDate'}}<br>
													{{ p.enddate | date: 'shortDate' }}
													</td>
													<td class="text-center">{{ p.qtyleft }}</td>
													<td class="text-center">{{ p.qoh }}</td>
													<td class="text-center">{{ p.age }}</td>
													<td class="text-right leftb max-td-small" *ngFor="let x of pricelvs">
														{{ p.prices[x.typeabbrev] | currency}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</mat-tab>
								<mat-tab label="Expired">
									<table class="table table-sm">
										<tr>
											<th>Stock ID</th>
											<th>Description</th>
											<th>Start Date</th>
											<th>End Date</th>
											<th>Qty Remaning</th>
											<th class="text-right" *ngFor="let x of pricelvs">{{x.sales_type}}</th>
											<th></th>
										</tr>
										<tr *ngFor="let p of inactive">

											<td>{{ p.stockid }}</td>
											<td>{{ p.description }}</td>
											<td>{{ p.startdate | date: 'shortDate'}}</td>
											<td>{{ p.enddate | date: 'shortDate'}}</td>
											<td class="text-center leftb">{{ p.qtyleft }}</td>
											<td class="text-right leftb" *ngFor="let x of pricelvs">{{ p.prices[x.typeabbrev] | currency }}</td>
											<td>
												<button mat-stroked-button color="danger" aria-label="" (click)="removeSpecial(p)" class="mt-2">
													<mat-icon>remove</mat-icon>
												</button>
												<button mat-stroked-button color="success" aria-label="" (click)="editSpecial(p)" class="mt-2">
													<mat-icon>edit</mat-icon>
												</button>
											</td>
										</tr>
									</table>
								</mat-tab>
							</mat-tab-group>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
