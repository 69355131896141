import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class CustomerService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public getOptions = {
		headers: new HttpHeaders({

		})
	};

	public getStatmentCounts(data: any) {
		const method = 'globals/settings/statementconfig';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public autoAllocatteMatching(data: any) {
		const method = 'globals/settings/statementconfig';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getStatementConfig() {
		const method = 'globals/settings/statementconfig';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getScheduledEmails(data:any) {
		const method = 'statements/scheduled';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public scheduleEmail(data:any) {
		const method = 'statements/schedule';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeJob(data:any) {
		const method = 'statements/scheduleremove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public saveStatementConfig(data:any) {
		const method = 'globals/settings/statementconfigsave';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getBatchStatements(data: any) {
		const method = 'statements';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getStatements(data: any) {
		const method = 'customers/statements';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getCartDetails(data: any) {
		const method = 'cart';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public sendStatement(data: any) {
		const method = 'customers/statements/email';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getCdet(data: any) {
		const method = 'documents/cdet';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}


	public getRaXls(data:any) {
		const method = 'customers/trialbalance/getraxls';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getRa(data:any) {
		const method = 'customers/trialbalance/getrapdf';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public searchTransactions(debtor: any, keywords: any) {
		const method = 'customers/history/transactionsearh&debtorno=' + debtor + '&transno=' + encodeURIComponent(keywords);
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getCustomerNotes(data: any) {
		const method = 'customers/getdebtornotes';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public searchItemTransactions(debtor: any, keywords: any) {
		const method = 'customers/history/transactionsearh&debtorno=' + debtor + '&keywords=' + encodeURIComponent(keywords);
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getAllCustomersCount(data: any = []) {
		const method = 'customers/all/countall';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getAllCustomers(data: any = []) {
		const method = 'customers/all';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getCurrentOnline() {
		const method = 'customers/dashboard/online';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getCurrentCarts() {
		const method = 'customers/dashboard/carts';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}


	public searchCustomerHistory(data: any) {
		const method = 'customers/history';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getCustomerHistory(debtor: any) {
		const method = 'customers/history&debtorno=' + debtor
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getCustomerHistoryItem(debtor: any, stockid: any) {
		const method = 'customers/history/item&debtorno=' + debtor + '&stockid=' + stockid;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getCustomerHistoryItems(debtor: any, keywords: any) {
		const method = 'customers/history/items&debtorno=' + debtor + '&keywords=' + keywords;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}


	public getInactiveCustomersCount(data: any) {
		const method = 'customers/all/inactivecount';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getInactiveCustomers(data: any) {
		const method = 'customers/all/inactive';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getNoPurchaseCustomersCount(data: any) {
		const method = 'customers/all/nopurchasecount';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getNewCustomers(data: any) {
		const method = 'customers/all/newcustomers';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getNoPurchaseCustomers(data: any) {
		const method = 'customers/all/nopurchase';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getOobCustomers() {
		const method = 'customers/all/oob';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getDisabledCustomers() {
		const method = 'customers/all/disabled';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getMatchingDocs() {
		const method = 'customers/trialbalance/matchingdocs';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getMatchingTypeDocs() {
		const method = 'customers/trialbalance/matchingdocstype';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}
	public getCustomerCreditableInvoices(data: any) {
		const method = 'customers/transactions/creditableinvoices';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getCustomerOrders(debtorno: any, keywords: any) {
		const method = 'customers/transactions/credits&debtorno=' + debtorno + '&keywords=' + keywords;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getTrialBalance() {
		const method = 'customers/trialbalance';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}
	public saveGroup(data: any) {
		const method = 'customers/savegroup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getGroups() {
		const method = 'customers/getgroups';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public removeGroupMember(id: any) {
		const method = 'customers/removemember&id=' + id;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public removeGroup(id: any) {
		const method = 'customers/removegroup&id=' + id;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	//CUSTOMER EDIT
	public uploadFile(formdata: any, customer: any) {
		const method = 'customers/filedrop&debtorno=' + customer;
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}
	public removeFile(data: any) {
		const method = 'customers/removefile';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, method)));
	}

	public removeBranch(debtorno: any, branch: any) {
		const method = 'customers/removebranch';
		return this.http.post(this.setEndPoint(method), { debtorno: debtorno, branchcode: branch }, this.httpOptions).pipe(catchError(this.handleError(method, method)));
	}

	public updateDebtorRefused(data: any) {
		const method = 'customers/updatedebtorrefused';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}

	public updateDebtorActive(data: any) {
		const method = 'customers/updatedebtoractive';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}



	public updateDebtorAr(settings: any) {
		const method = 'customers/updatedebtorar';
		return this.http.post(this.setEndPoint(method), settings, this.httpOptions).pipe(catchError(this.handleError(method, settings)));
	}

	public updateDebtorContacts(settings: any) {
		const method = 'customers/updatedebtorcontacts';
		return this.http.post(this.setEndPoint(method), settings, this.httpOptions).pipe(catchError(this.handleError(method, settings)));
	}

	public getDebtorContacts(debtorno: any) {
		const method = 'customers/getdebtorcontacts&debtorno=' + debtorno;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}


	public updateDebtorNotes(settings: any) {
		const method = 'customers/updatedebtornotes';
		return this.http.post(this.setEndPoint(method), settings, this.httpOptions).pipe(catchError(this.handleError(method, settings)));
	}

	public getPriceMatrix(debtorno: any) {
		const method = 'customers/pricematrix&debtorno='+debtorno;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public saveMatrix(settings: any) {
		const method = 'customers/savepricematrix';
		return this.http.post(this.setEndPoint(method), settings, this.httpOptions).pipe(catchError(this.handleError(method, settings)));
	}

	public updateCustomerSettings(settings: any) {
		const method = 'customers/updatesettings';
		return this.http.post(this.setEndPoint(method), settings, this.httpOptions).pipe(catchError(this.handleError(method, settings)));
	}

	public createCustomer(debtor: any, branch: any, contacts: any = []) {
		const method = 'customers/create';
		return this.http.post(this.setEndPoint(method), { debtor: debtor, branch: branch, contact: contacts }, this.httpOptions).pipe(catchError(this.handleError(method, debtor)));
	}

	public getDebtorWithBranch(customerid: any, branchcode: any) {
		const method = 'customers/getcustomer&debtorno=' + customerid + '&branchcode=' + branchcode;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getDebtornoFromTrans(transno: any) {
		const method = 'customers/getdebtornofromtrans&transno=' + transno;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getDebtor(customerid: any) {
		const method = 'customers/getcustomer&debtorno=' + customerid;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public zipCodeCheck(data: any) {
		const method = 'globals/taxes/zipcodelookup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}



	public removeDebtor(data: any) {
		const method = 'customers/removecustomer';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}

	public getDebtorMaster(customerid: any) {
		const method = 'customers/getdebtor&debtorno=' + customerid;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}


	public getAdjustmentData(data: any) {
		const method = 'tools/transaction/gettransaction&id=' + data.id;
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}

	public adjustView(data: any){
		const method = 'tools/transaction/adjustview';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)))
	}

	public adjustARView(data: any){
		const method = 'tools/transaction/aradjustview';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)))
	}


	public adjustReverse(data: any){
		const method = 'tools/transaction/adjusttransactions';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)))
	}


	public adjustAR(data: any){
		const method = 'tools/transaction/aradjusttransactions';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)))
	}

	public glView(data: any){
		const method = 'tools/transaction/viewgl';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)))
	}


	public getDebtorTransactionsRange(data: any) {
		const method = 'customers/transactions&debtorno=' + data.debtorno;
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}

	public mergeDebtors(data: any) {
		const method = 'customers/merge&debtorno=' + data.debtorno;
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}

	public moveDebtorTransactions(data: any) {
		const method = 'customers/movetransactions&debtorno=' + data.debtorno;
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}

	public getDebtorTransactions(customerid: any) {
		const method = 'customers/transactions&debtorno=' + customerid;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getBranches(customerid: any) {
		const method = 'customers/getbranches&debtorno=' + customerid;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public updateDebtor(debtor: any) {
		const method = 'customers/debtorupdate';
		return this.http.post(this.setEndPoint(method), debtor, this.httpOptions).pipe(catchError(this.handleError(method, debtor)));
	}

	public updateBranch(branchdata: any) {
		const method = 'customers/branchupdate';
		return this.http.post(this.setEndPoint(method), { branchdata: branchdata }, this.httpOptions).pipe(catchError(this.handleError(method, branchdata)));
	}

	public addressCompare(input: any) {
		const method = 'customers/addresscompare';
		return this.http.post(this.setEndPoint(method), input, this.httpOptions).pipe(catchError(this.handleError(method, input)));
	}
	public getClubs(){
		const method = 'customers/getclubs';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}
	public addCustomerClub(input: any) {
		const method = 'customers/addclubcust';
		return this.http.post(this.setEndPoint(method), input, this.httpOptions).pipe(catchError(this.handleError(method, input)));
	}
	public removeCustomerClub(input: any) {
		const method = 'customers/removeclubcust';
		return this.http.post(this.setEndPoint(method), input, this.httpOptions).pipe(catchError(this.handleError(method, input)));
	}
	public saveClub(input: any) {
		const method = 'customers/saveclub';
		return this.http.post(this.setEndPoint(method), input, this.httpOptions).pipe(catchError(this.handleError(method, input)));
	}
	public removeClub(input: any) {
		const method = 'customers/removeclub';
		return this.http.post(this.setEndPoint(method), input, this.httpOptions).pipe(catchError(this.handleError(method, input)));
	}
	public getCities(){
		const method = 'customers/cities';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}
	public getStates(){
		const method = 'customers/statecodes';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}
	public getCustomerExport(input: any) {
		const method = 'customers/exportlist';
		return this.http.post(this.setEndPoint(method), input, this.httpOptions).pipe(catchError(this.handleError(method, input)));
	}
	public getAccountInq(customerid: any) {
		const method = 'customers/inquiry&debtorno=' + customerid;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getCustomer(customerid: any, branchcode: any) {
		const method = 'customers/getcustomer&debtorno=' + customerid + '&branchcode=' + branchcode;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}
	public getOeCustomerSearch(input: any) {
		const method = 'orders/customers&keywords=' + encodeURIComponent(input);
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getCustomerSearch(input: any) {
		const method = 'customers';
		return this.http.post(this.setEndPoint(method), input, this.httpOptions).pipe(catchError(this.handleError(method, input)));
	}

	public getCustomers() {
		const method = 'customers';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getInvoices(customerid) {
		const method = 'customers/getorders&customerid=' + customerid;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}
	public getDealerCodes(data: any) {
		const method = 'customers/dealercodes&debtorno=' + data.debtorno;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}
	public saveDealerCode(data: any) {
		const method = 'customers/dealercodes/save';
		return this.http.post(this.setEndPoint(method), { dcodedata: data }, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}
	public removeDealerCode(data: any) {
		const method = 'customers/dealercodes/remove';
		return this.http.post(this.setEndPoint(method), { autoid: data }, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}
	public getConsignment(data: any) {
		const method = 'customers/consignment&debtorno=' + data.debtorno;
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}
	public saveConsignment(data: any) {
		const method = 'customers/consignment/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}
	public removeConsignment(data: any) {
		const method = 'customers/consignment/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}
	public updateConsignment(data: any) {
		const method = 'customers/consignment/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}
	public consignmentHist(data: any) {
		const method = 'customers/consignment/history';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(catchError(this.handleError(method, data)));
	}

	public getCustomerTypes(){
		const method = 'customers/customertypes';
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}


	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}

			return error;
		};
	}

}
