<ng-template #detailsModal let-modal>
	<ng-container *ngIf="order_modal_ready == true">
		<div class="modal-header">
			<h3>ORDER #<b>{{orderStatus[0].orderno}}</b> STATUS</h3>
		</div>
		<div class="modal-body">
			<table class="text-center">
				<tr>
					<th>STOCKID</th>
					<th>BIN</th>
					<th>PICKED</th>
				</tr>
				<tr *ngFor="let i of orderStatus" [ngClass]="{'nopick': (i.picked == 0), 'partialpick': (i.picked > 0 && i.picked < i.pick), 'completepick':(i.picked == i.pick)}">
					<td>{{i.stockid}}</td>
					<td>{{i.bin}}</td>
					<td style="width: 100%; max-width: 190px; margin: auto; display: flex; justify-content: space-evenly; flex-direction: row;">
						<div style="line-height: 40px; width: 50px;">{{i.picked | number}}/{{i.pick}}</div>
						<div>
							<button class=" btn btn-just-icon btn-white btn-fab btn-sm" style="background: #ffffff54" (click)="removePick(i);" (keydown.enter)="$event.stopPropogation">
								<small><i class="material-icons">remove</i></small>
							</button>
						</div>
						<!-- <button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(i);" *ngIf="i.picked < i.pick">
					<small><i class="material-icons">add</i></small>
				</button> -->
					</td>
				</tr>
			</table>
		</div>
		<div class="modal-footer">
			<button (click)="printPackingList(orderStatus[0].orderno)" mat-raised-button>
				<mat-icon class="mr-1">print</mat-icon>Packing List
			</button>
		</div>
	</ng-container>
</ng-template>
<div class="main-content pl-0 pr-0">
	<div class="container-fluid">
		<div class="card pr-0 pl-0">
			<div class="card-header card-header-{{color}}-6">
				<div class="row m-0 p-0">
					<div class="col-10">
						<h4 class="card-title">
							<mat-icon (click)="back()">arrow_left</mat-icon>
							Picking
						</h4>
					</div>
					<div class="col-2 text-right" (click)="toggleFilters()">
						<h4 class="card-title">
							<mat-icon [innerHTML]="filterson ? 'unfold_less':'unfold_more'"></mat-icon>
						</h4>
					</div>
				</div>
				<p class="card-category text-white text-right">
					<mat-slide-toggle [formControl]="actionControl" (checked)="actionControl.value">Scan To Pick</mat-slide-toggle>
				</p>
				<div class="messages row">
				</div>
			</div>

			<div class="card-body">
				<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" (selectedTabChange)="tabChanged($event)">
					<mat-tab label="Order Pick ({{ getRemainingPicks() }})" *ngIf="this.config.env.package == 'beauty'" class="no-overflow fill-available">
						<div class="container-fluid tp-box mwidth" [@flipState]="flip">
							<div class="tp-box__side tp-box__back 123" [ngClass]="flip == 'inactive' ? 'd-none' : ''">

								<div class="row m-0 p-0">
									<div class="col-6 m-0 p-0">
										<h3>PICKING ORDER #{{ pickingorderOrderno }}</h3>
										<h4>{{ getRemainingPickItemsForOrder() }} QTY {{getRemainingLines() }} LINES </h4>
									</div>
									<div class="col-6 text-right m-0 p-0 mt-3">
										<button mat-raised-button color="primary" (click)="resetList()">CLOSE</button>&nbsp;&nbsp;
										<button mat-raised-button color="accent" (click)="completeOrderPick()">COMPLETE</button>
									</div>
								</div>

								<mat-form-field appearance="outline">
									<mat-label>Search/Scan an item</mat-label>
									<input #scanPickingV tabindex="0" autofocus autocomplete="off" aria-hidden="false" matInput [formControl]="scanPicking" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); scanItemToOrderTable()">
									<!--
									<span matSuffix *ngIf="itemScanForm.get('stockidsearch').value.length">
										<button mat-icon-button color="accent" (click)="itemScanForm.get('stockidsearch').setValue('');searchItems();">
											<mat-icon>clear</mat-icon>
										</button>
									</span>
									-->
									<span matSuffix>
										<button mat-icon-button color="white" (click)="scanItemToOrderTable()">
											<mat-icon>search</mat-icon>
										</button>
									</span>

								</mat-form-field>
								<ng-container *ngIf="pickingorder && pickingorder.length">
									<ng-container *ngFor="let item of pickingorder" >


										<div class="row pickitem mt-2 ml-0 mr-0 pl-0 pr-0"  [ngClass]="{'highlight': item.highlight, 'picked-item': item.complete}">
											<div class="col-12 col-md-8 m-0 p-0">
												<div class="row m-0 p-0">
													<div class="col-md-6 m-0 p-0">
														<ul class="entry-list m-0 p-0">
															<li>
																<div class="spec-label">CUSTOMER</div>
																<div class="spec-value"><b>({{ item.debtorno }}.{{ item.branchcode }})</b> {{ item.customer }}</div>
															</li>
															<li>
																<div class="spec-label">WANTED</div>
																<div class="spec-value" [ngClass]="{notdue: item.info.daystilldue > 0}">{{ item.date_wanted }}</div>
															</li>
															<li>
																<div class="spec-label">ORDER#</div>
																<div class="spec-value">{{item.orderno}} @ x {{item.ordered }} </div>
															</li>
															<li>
																<div class="spec-label">MFGPART#</div>
																<div class="spec-value">{{ item.mfgpart }}</div>
															</li>
														</ul>
													</div>
													<div class="col-md-6 m-0 p-0">
														<ul class="entry-list m-0 p-0">
															<li>
																<div class="spec-label">ITEM</div>
																<div class="spec-value"><b>{{ item.stockid }}</b></div>
															</li>
															<li>
																<div class="spec-label">DESC</div>
																<div class="spec-value">{{ item.description }}</div>
															</li>
															<li>
																<div class="spec-label">CITY</div>
																<div class="spec-value">{{ item.city }} <b>{{ routeName(item.route) }}</b></div>
															</li>
															<li>
																<div class="spec-label">BIN</div>
																<div class="spec-value"><b>{{ item.bin }}</b> ({{ item.qoh }})</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-4 mt-0 mb-0 pt-0 pb-0 text-right">
												<div class="form-group mt-0 mb-0 pt-0 pb-0 ">
													<ng-container *ngIf="(item.claimed == '1' && item.claimed_user == user.user.userid) || item.claimed == '0'">
														<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(item)" (keydown.enter)="$event.stopPropogation">
															<small>
																<i class="material-icons">remove</i></small>
														</button>
														<span class="mr-2 ml-2" [ngClass]="item.picked < item.pick ? 'text-danger': 'text-success'">
															<mat-input>
																<input inputmode="none" class="text-right col-4 col-md-2 border-top-0 border-left-0 border-right-0" type="text" [(value)]="item.picked" [ngClass]="item.picked < item.pick ? 'text-danger': 'text-success'" (input)="setQty($event.target.value, item)">
																<span matSuffix> / <b>{{ item.pick }}</b></span>
															</mat-input>
														</span>
														<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(item)">
															<small><i class="material-icons">add</i></small>
														</button>


														&nbsp;
														<mat-checkbox [checked]='item.complete' (change)="setCheckValue($event, item)" class="ml-2 mb-0 mat-checkbox mat-accent mt-4"></mat-checkbox>
													</ng-container>

													<ng-container *ngIf="(item.claimed == '1' && item.claimed_user != user.user.userid)">
														Loaded : {{item.loaded}}/<b>{{ item.pick }}</b>
													</ng-container>
												</div>
											</div>
											<mat-divider></mat-divider>
										</div>
									</ng-container>
								</ng-container>
							</div>
							<div id="frontside" class="row tp-box__side tp-box__front w-100" [ngClass]="flip == 'active' ? '' : ''">
								<div class="row m-0 p-0 w-100">

									<div class="col-12">
										<h3>{{ getRemainingPicks() }} ORDER(S) TO PICK </h3>
									</div>
									<div class="col-12">
										<mat-form-field appearance="outline">
											<mat-label>Order Search</mat-label>
											<input matInput #orderSearch tabindex="-1" aria-hidden="true" autocomplete="off" [formControl]="ordersearch" value="" class="text-right">
											<span matSuffix>
												<button tabindex="-1" aria-hidden="true" mat-icon-button color="white" (click)="searchOrdersTable()">
													<mat-icon>search</mat-icon>
												</button>
											</span>
										</mat-form-field>
									</div>
									<div class="col-12">
										<div class="table table-fixed table-hover table-striped">
											<table class="table table-fixed">
												<thead>
													<tr>
														<th>CUSTOMER#</th>
														<th>NAME</th>
														<th>WANTED</th>
														<th class="text-left">ORDER#</th>
														<th class="text-right">NET$</th>
														<th class="text-center">ITEMS#</th>
														<th></th>
													</tr>
												</thead>
												<tbody *ngIf="pickingorders && pickingorders.length">
													<ng-container *ngFor="let item of pickingorders">
														<!-- 24 is already checked / loaded -->
														<tr (click)="loadOrder(item.orderno)" *ngIf="asInt(item.orderstatusid) < 21">
															<td><b>({{ item.debtorno }}.{{ item.branchcode }})</b></td>
															<td>{{ item.deliverto }}</td>
															<td [ngClass]="{notdue: item?.daystilldue > 0}">{{ item.deliverydate }}</td>
															<td class="text-left">{{item.orderno}}</td>
															<td *ngIf="item.details" class="text-right">{{ item.details.ordervalue | currency }}</td>
															<td *ngIf="item.details" class="text-center">{{ item.details.sumquantity }}</td>
															<td class="text-right"><button mat-raised-button color="accent">PICK</button></td>
														</tr>
													</ng-container>
													<ng-container *ngIf="getRemainingPicks() == 0">
														<tr>
															<td colspan="7" class="text-center">
																<h3>NO ORDERS TO PICK</h3>
															</td>
														</tr>
													</ng-container>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-tab>
					<mat-tab label="Item Pick" class="no-overflow fill-available">
						<ng-container>
							<div class="row mt-2 mb-2" *ngIf="filterson">
								<!-- <div class="col-md-3 mt-3">
										<button tabindex="-1" class="btn btn-primary btn-sm d-none d-lg-inline" (click)="exportExcel(picks)">XLS</button>
									</div> -->
								<div class="col-4 col-md-4">
									<mat-form-field appearance="outline">
										<mat-label>Warehouse</mat-label>
										<mat-select tabindex="-1" aria-hidden="true" [formControl]="loccode">
											<mat-option *ngFor="let k of locations" [value]="k.loccode">
												{{ k.loccode }}: {{ k.locationname }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-4 col-md-4">
									<mat-form-field appearance="outline">
										<mat-label>Route</mat-label>
										<mat-select tabindex="-1" aria-hidden="true" [formControl]="routesControl" (selectionChange)="filterPicked()">
											<mat-option [value]="'ALL'">ALL</mat-option>
											<mat-option *ngFor="let k of routes" [value]="k.route_Id">
												{{ k.route_Id }}: {{ k.route_description }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-4 col-md-4">

									<mat-form-field appearance="outline">
										<mat-label>Order Search</mat-label>
										<input matInput #orderSearch abindex="-1" aria-hidden="true" autocomplete="off" [formControl]="filterOrdersControl" value="" class="text-right" (input)="searchOrders()">
										<span matSuffix>
											<button tabindex="-1" aria-hidden="true" mat-icon-button color="white" (click)="searchOrders()">
												<mat-icon>search</mat-icon>
											</button>
										</span>
									</mat-form-field>
								</div>
								<!--
									<div class="col-6 col-md-2">
										<mat-form-field appearance="outline">
											<mat-label>Bin</mat-label>
											<mat-select tabindex="-1" aria-hidden="true" [formControl]="filterBinsControl" (selectionChange)="filterBins()">
												<mat-option [value]="'ALL'">All</mat-option>
												<mat-option *ngFor="let v of bins" [value]="v">
													{{ v }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									-->
							</div>
							<form [formGroup]="itemScanForm" class="needs-validation product-add mt-3" novalidate="" autocomplete="off">
								<div class="row">
									<div tabindex="-1" [ngClass]="{'d-none' : !filterson , 'col-md-3': filterson }">
										<mat-form-field appearance="outline">
											<mat-label>Bin Search</mat-label>
											<input matInput #binsearcher abindex="-1" aria-hidden="true" autocomplete="off" [formControl]="binsearch" value="" class="text-right" (input)="searchBins()">
											<span matSuffix>
												<button tabindex="-1" aria-hidden="true" mat-icon-button color="white" (click)="searchBins()">
													<mat-icon>search</mat-icon>
												</button>
											</span>
										</mat-form-field>
									</div>
									<div [ngClass]="{'col-md-1' : !filterson , 'col-md-2': filterson }">
										<mat-form-field appearance="outline">
											<mat-label>Order Status</mat-label>
											<mat-select tabindex="-1" aria-hidden="true" formControlName="itemfiltertypeselect" (selectionChange)="filterPicked()" multiple>
												<mat-option *ngFor="let v of pickedFilter" [value]="v.value">
													{{v.label}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div [ngClass]="{'col-md-1' : !filterson , 'col-md-2': filterson }">
										<mat-form-field appearance="outline">
											<mat-select [formControl]="defaultshipvia" multiple (selectionChange)="filterPicked()">
												<mat-option *ngFor="let ship of shipvia" [value]="ship.shipper_id">
													{{ ship.shippername }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>

									<div [ngClass]="{'col-md-2': !filterson, 'col-md-3': filterson}">
									  <mat-form-field appearance="outline">
										<mat-label>Bay</mat-label>
										<mat-select
										  tabindex="-1"
										  aria-hidden="true"
										  formControlName="baySelect"
										  (selectionChange)="filterPicked()"
										  multiple>
										  <!-- <mat-optgroup *ngFor="let group of bayKeys" [label]="group">
											<mat-option *ngFor="let truck of bays[group]" [value]="truck">
											  {{ truck.truck_name }}
											</mat-option>
										  </mat-optgroup>
									   -->
									   	<mat-option *ngFor="let bay of bayKeys" [value]="bay">
											 {{ bay }}
										   </mat-option>
										</mat-select>
									  </mat-form-field>
									</div>

									<div [ngClass]="{'col-md-8' : !filterson , 'col-md-6': filterson }">
										<mat-form-field appearance="outline">
											<mat-label>Search/Scan an item</mat-label>
											<input #stockidsearchtwo tabindex="0" autofocus autocomplete="off" aria-hidden="false" matInput formControlName="stockidsearch" required value="" class="text-right" (input)="searchItems()" (keyup.enter)="$event.preventDefault(); scanItem()">
											<!--
												<span matSuffix *ngIf="itemScanForm.get('stockidsearch').value.length">
													<button mat-icon-button color="accent" (click)="itemScanForm.get('stockidsearch').setValue('');searchItems();">
														<mat-icon>clear</mat-icon>
													</button>
												</span>

												<span matSuffix>
													<button mat-icon-button color="white" (click)="scanItem()">
														<mat-icon>search</mat-icon>
													</button>
												</span>
											-->
										</mat-form-field>
									</div>
								</div>
							</form>
							<div class="col-md-12 text-center mr-auto ml-auto mt-4">
								{{ getFilterName() }} {{ picks.length }} Results
								<span *ngIf="!picks.length">
									<br /> {{ picks.length }} Results
								</span>
							</div>
							<ng-container *ngFor="let item of picks" >
								<div class="row pickitem mt-2" [ngClass]="{'picked-item': item.complete}" >
									<div class="claim-handle-placeholder">
										<div class="claim-handle w-100 text-center taller-height" [ngClass]="{'claimed-users': (item.claimed == '1' && item.claimed_user == user.user.userid), 'claimed': item.claimed == '1' && item.claimed_user != user.user.userid }" (click)="togglePickClaim(item)">
											<span *ngIf="item.claimed == '1'">
												CLAIMED <span *ngIf="item.claimed_user">{{ item.claimed_user }}</span>
											</span>
											<span *ngIf="item.claimed == '0'">
												CLAIM
											</span>
										</div>
									</div>
									<div class="col-12 col-md-8 m-0 p-0">
										<div class="row m-0 p-2">
											<!-- Left Column -->
											<div class="col-md-6 p-2">
												<ul class="entry-list list-unstyled">
													<!-- Customer Information -->
													<li>
														<div class="spec-label">CUSTOMER</div>
														<div class="spec-value">
															<b>({{ item.debtorno }}.{{ item.branchcode }})</b> {{ item.customer }}
														</div>
													</li>
													<!-- Order Information -->
													<li>
														<div class="spec-label">ORDER#</div>
														<div class="spec-value d-flex align-items-center" [ngClass]="item.partial_pick ? 'partialpick' : ''">
															<span>#{{ item.orderno }} x </span>
															&nbsp;&nbsp;
															<span class="badge ms-2" [ngClass]="item.partial_pick ? 'badge-warning' : 'badge-success'">
																<span class="qty-highlight ms-2"><b>{{ item.ordered }}</b></span>
															</span>
															&nbsp;&nbsp;
														</div>
													</li>

													<!-- Ship Via -->
													<li>
														<div class="spec-label">SHIPVIA#</div>
														<div class="spec-value">{{ item.shipvia }}</div>
													</li>
													<!-- Manufacturer Part -->
													<li>
														<div class="spec-label">MFGPART#</div>
														<div class="spec-value">{{ item.mfgpart }}</div>
													</li>
												</ul>
											</div>

											<!-- Right Column -->
											<div class="col-md-6 p-2">
												<ul class="entry-list list-unstyled">
													<!-- Item Information -->
													<li>
														<div class="spec-label">ITEM</div>
														<div class="spec-value"><b>{{ item.stockid }}</b></div>
													</li>
													<!-- Description -->
													<li>
														<div class="spec-label">DESC</div>
														<div class="spec-value">{{ item.description }}</div>
													</li>
													<!-- Bin Location -->
													<li>
														<div class="spec-label">BIN</div>
														<div class="spec-value"><b>{{ item.bin }}</b> ({{ item.qoh }})</div>
													</li>
													<!-- City and Route -->
													<li>
														<div class="spec-label">CITY</div>
														<div class="spec-value">{{ item.city }} <b>{{ routeName(item.route) }}</b></div>
													</li>
												</ul>
											</div>

											<!-- Notes Section -->
											<div class="col-12 text-center p-2">
												<div class="notes">
													{{ noNewLines(item.notes) }}
												</div>
											</div>
										</div>

									</div>
									<div class="col-12 col-md-4 mt-0 mb-0 pt-0 pb-0 text-right">
										<div class="form-group mt-0 mb-0 pt-0 pb-0 ">
											<ng-container *ngIf="(item.claimed == '1' && item.claimed_user == user.user.userid) || item.claimed == '0'">
												<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(item)" (keydown.enter)="$event.stopPropogation">
													<small>
														<i class="material-icons">remove</i></small>
												</button>
												<span class="mr-2 ml-2" [ngClass]="item.picked < item.pick ? 'text-danger': 'text-success'">
													<mat-input>
														<input inputmode="none" class="text-right col-4 col-md-2 border-top-0 border-left-0 border-right-0" type="text" [(value)]="item.picked" [ngClass]="item.picked < item.pick ? 'text-danger': 'text-success'" (input)="setQty($event.target.value, item)">
														<span matSuffix> / <b>{{ item.pick }}</b></span>
													</mat-input>
												</span>
												<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(item)">
													<small><i class="material-icons">add</i></small>
												</button>
												&nbsp;
												<mat-checkbox [checked]='item.complete' (change)="setCheckValue($event, item)" class="ml-2 mb-0 mat-checkbox mat-accent mt-4"></mat-checkbox>
											</ng-container>

											<ng-container *ngIf="(item.claimed == '1' && item.claimed_user != user.user.userid)">
												Picked : {{item.picked}}/<b>{{ item.pick }}</b>
											</ng-container>
										</div>
									</div>
									<mat-divider></mat-divider>
								</div>
							</ng-container>
						</ng-container>
					</mat-tab>



					<mat-tab label="Packing Lists" *ngIf="this.config.env.package == 'beauty'" (selectedTabChange)="tabChanged($event)">
						<div class="row">
							<div class="col-3 col-md-3">
								<mat-form-field color="accent">
									<mat-label>Enter a date range</mat-label>
									<div style="display: flex !important; margin-top: 10px;">
										<mat-date-range-input [rangePicker]="picker" style="height: 35px; margin-top: 3px;">
											<input matStartDate placeholder="Start date" [value]="startdate" (dateInput)="startRangeAltered({start: $event})">
											<input matEndDate placeholder="End date" [value]="enddate" (dateInput)="endRangeAltered({end: $event})">
										</mat-date-range-input>
										<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-date-range-picker #picker></mat-date-range-picker>
									</div>
								</mat-form-field>
							</div>

							<div class="col-3 col-md-3" style="margin-top: 33px;">
								<mat-form-field>
									<mat-label>Order search</mat-label>
									<input matInput type="text" [formControl]="packing_order_search">
									<button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
										<mat-icon>clear</mat-icon>
									</button>
								</mat-form-field>
							</div>

							<div class="col-6 col-md-6"></div>

						</div>



						<div class="table table-responsive text-center searchlist" *ngIf="pl_loading">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>
						<div class="table table-responsive searchlist" #searhlist>
							<table mat-table [dataSource]="pl_datasource_filtered" class="mat-elevation-z8" *ngIf="pl_datasource_filtered">

								<ng-container matColumnDef="orderno">
									<th mat-header-cell *matHeaderCellDef> Order # </th>
									<td mat-cell *matCellDef="let element"> {{element.orderno}} </td>
								</ng-container>

								<ng-container matColumnDef="items">
									<th mat-header-cell *matHeaderCellDef> Items </th>
									<td mat-cell *matCellDef="let element"> {{element.items}} </td>
								</ng-container>

								<ng-container matColumnDef="picks">
									<th mat-header-cell *matHeaderCellDef> Picks </th>
									<td mat-cell *matCellDef="let element">
										<mat-icon class="eyeball" (click)="openModal(element.orderno)">visibility</mat-icon>&nbsp;
										<span>{{element.picked}} / {{element.picks}}</span>
									</td>
								</ng-container>


								<ng-container matColumnDef="printedpackingslip">
									<th mat-header-cell *matHeaderCellDef> Printed </th>
									<td mat-cell *matCellDef="let element">
										<ng-container *ngIf="element.printedpackingslip == 0; else reprint">
											<a class='w-50' mat-raised-button (click)="printPackingList(element.orderno)">
												<span>
													Print &nbsp;<mat-icon style="float: right;">print</mat-icon>
												</span>
											</a>
										</ng-container>
										<ng-template #reprint>
											<a class="w-50" mat-raised-button (click)="printPackingList(element.orderno)">
												<span>
													Reprint &nbsp;<mat-icon style="float: right;">print</mat-icon>
												</span>
											</a>
										</ng-template>
									</td>
								</ng-container>

								<ng-container matColumnDef="orddate">
									<th mat-header-cell *matHeaderCellDef> Order Date </th>
									<td mat-cell *matCellDef="let element">{{element.orddate}}</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

							</table>
						</div>
						<mat-paginator #paginator [pageSizeOptions]="[25,50,100]" showFirstLastButtons></mat-paginator>
					</mat-tab>


					<!-- on hold
						<mat-tab label="Item Put">
							<ng-container>
								<ng-container *ngFor="let i of picks_in_hand">
									<li class="dd-item dd3-item item-box">
										<div class="dd-handle dd3-handle">&nbsp;</div>
										<mat-card class="dd3-content p-1 ">
											<mat-card-content>
												<div class="row m-0 p-0">
													<div class="col-10 col-md-10 m-0 p-0">
														<ul class="entry-list m-0 p-0">
															<li>
																<div class="spec-label">ITEM#</div>
																<div class="spec-value">{{ i.stockid }}</div>
															</li>
															<li>
																<div class="spec-label">DESC#</div>
																<div class="spec-value">{{ i.description }}</div>
															</li>

															<li>
																<div class="spec-label">ORDER#</div>
																<div class="spec-value">{{ i.orderno }}</div>
															</li>
															<li>
																<div class="spec-label">PICKED#</div>
																<div class="spec-value">{{ i.picked }}</div>
															</li>
														</ul>
													</div>
													<div class="col-2 col-md-2 mt-4 m-0 p-0 text-center">
														<button mat-mini-fab color="accent" (click)="itemToPlace(i)" *ngIf="!i.putbin">
															<mat-icon>file_download_done</mat-icon>
														</button>
														<button mat-mini-fab color="accent" (click)="removeItemToPlace(i)" *ngIf="i.loaded">
															<mat-icon>remove</mat-icon>
														</button>
													</div>
												</div>
											</mat-card-content>
										</mat-card>
									</li>
								</ng-container>
							</ng-container>
						</mat-tab>
						-->
				</mat-tab-group>
			</div>
			<div class="card-body" *ngIf="!picks.length">
				<app-item-short-search></app-item-short-search>
			</div>
		</div>
	</div>
</div>
