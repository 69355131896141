<p>warehouse-checking-wifi works!</p>
<ng-template #assignModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Pick Devices Bay</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-8">
				<button mat-raised-button color="primary" (click)="manualDeviceAssign('1')">Assign Bay 1</button>
				<br>
				<br>
				<button mat-raised-button color="primary" (click)="manualDeviceAssign('2')">Assign Bay 2</button>
				<br>
				<br>
				<button mat-raised-button color="primary" (click)="manualDeviceAssign('3')">Assign Bay 3</button>
			</div>
			<!-- <div class="col-4">
				<button mat-raised-button class="bg-success text-white" (click)="manualDeviceAssign('')">All</button>
			</div> -->
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="main-content" *ngIf="apploaded">
	<div class="row m-0 w-100 bay-container">
		<ng-container *ngFor="let bay of bays | keyvalue">
			<ng-container *ngIf="assignedBay !== '' && assignedBay == bay.value.id">
				<button mat-stroked-button (click)="openBaySelection()" color="primary">Switch Bay</button>
				<button mat-stroked-button (click)="manualDeviceAssign(bay.value.id)" color="primary" class="text-right">Re-sync</button>
			</ng-container>
			<div class="card p-3" [ngClass]="(bay.value.removing.status ? ' bayRemoveMode ' : '') + '' + (assignedBay ? 'col-12 ' : 'col-4 bay3')" *ngIf="assignedBay == '' || assignedBay == bay.value.id">
				<div class="row p-0 m-0">
					<div class="col-4">
						<h2 [ngClass]="assignedBay ? 'font-phone-smaller text-nowrap' : 'text-nowrap'">BAY {{bay.value.id}}
							<ng-container *ngIf="assignedBay">
								<span class="font-phone-smallest">
									<span>{{bay.value.truck.name}}</span>
									<ng-container *ngIf="bay.value.truck.driver">
										({{bay.value.truck.driver}})
									</ng-container>
								</span>
							</ng-container>
							<span *ngIf="bay.value.finalizing"> FINALIZING... </span>
							<span *ngIf="bay.value.removing.status" [ngClass]="assignedBay == '' ? 'text-white bg-danger p-1 rounded-pill indicator' : 'text-danger indicator'">
								<ng-container *ngIf="assignedBay != ''"><mat-icon>delete</mat-icon></ng-container>
								<ng-container *ngIf="assignedBay == ''">REMOVING</ng-container>
							</span>
						</h2>
						<!-- <h4 *ngIf="bay.value.device.serial" class="text-nowrap">Device #: {{bay.value.device.serial}}</h4> -->
					</div>

					<div class="col-8 text-right" *ngIf="bay.value.total_items">
						<h4 class='font-weight-light med-normal-font' *ngIf="!assignedBay">
							<span>{{bay.value.truck.name}}</span>
							<br *ngIf="assignedBay">
							<ng-container *ngIf="bay.value.truck.driver">
								({{bay.value.truck.driver}})
							</ng-container>
						</h4>
						<h2 class="font-weight-bold" *ngIf="!assignedBay">{{bay.value.scans}} / {{bay.value.total_items}}</h2>
					</div>
					<ng-container *ngIf="!bay.value.error.status && !bay.value.finalizing || assignedBay">
						<h2 class="font-weight-bold" *ngIf="assignedBay">{{bay.value.scans}} / {{bay.value.total_items}}</h2>
						<!-- <button mat-mini-fab (click)="remove_shortcut(bay.value)" *ngIf="assignedBay"><mat-icon>delete</mat-icon></button> -->
						<mat-progress-bar class="mb-5 rounded bigger-bar" mode="determinate" [value]="(bay.value.scans / bay.value.total_items) * 100"></mat-progress-bar>
					</ng-container>

					<ng-container *ngIf="!bay.value.error.status && bay.value.finalizing">
						<mat-progress-bar class="mb-5 rounded bigger-bar" mode="indeterminate" ></mat-progress-bar>
					</ng-container>

					<ng-container *ngIf="bay.value.error.status && !assignedBay">
						<div class="p-0 m-25 w-100 med-normal-font text-center rounded-pill bg-danger text-white errorMessage">
							<h3 class="m-0">{{bay.value.error.message}}</h3>
						</div>
					</ng-container>

				</div>
				<div class="w-100 lastremove">
					<div class="w-100 display-4 bg-danger text-white rounded-pill pad-10 text-xl-center" *ngIf="bay.value.removing.last && bay.value.removing.status" >
						<h3 class="med-bold-font lh-5 text-center">{{bay.value.removing.last}}</h3>
					</div>
					<!-- <div class="row">
						<div class="col-10 m-0 p-0">
							<mat-form-field appearance="outline">
								<mat-label>scan tester</mat-label>
								<input matInput placeholder="" [(ngModel)]="bay1input">
							</mat-form-field>
						</div>
						<div class="col-1 p-0 m-0"><button class="w-100" (click)="sendScan(bay.value)">scan</button></div>
					</div> -->
				</div>
				<ng-container *ngIf="!bay.value.completing">
					<ul class="m-0 p-0 list-unstyled" *ngIf="bay.value.dispatch.length > 1 && !bay.value.completing">
						<li *ngFor="let ord of bay.value.dispatch; let top = index">
							<ng-container *ngIf="ord.ordertype != 0">
								<!-- active scan card -->
								<div class="card card-spacing" *ngIf="top == 1;" >
									<div class="card-header card-header-{{color}}-6">
										<ng-container *ngIf="!assignedBay || assignedBay && !bay.value.error.status">
											<span class="float-left">{{ord.header.brname}}</span>
											<span class="float-right font-weight-bolder xl-bold-font">#{{ord.header.orderno}}</span>
										</ng-container>
										<ng-container *ngIf="bay.value.error.status && assignedBay">
											<div class="p-0 m-25 w-100 smal-normal-font text-center bg-danger text-white errorMessage">
												<h3 class="m-0">{{bay.value.error.message}}</h3>
											</div>
										</ng-container>
									</div>
									<div class="card-body">
										<ul class="p-0 m-0 list-unstyled">
											<mat-form-field appearance="fill" *ngIf="assignedBay && showScanInput">
												<mat-label>Input</mat-label>
												<input matInput [formControl]="manualInput" (keyup.enter)="handleInput($event, bay.value)" #manualInputRef>
											</mat-form-field>
											<li *ngFor="let i of ord.orderdetails;">
												<ul class="m-0 p-0 larger-font display-4 list-unstyled" >
													<ng-container *ngIf="i.qtyloaded < i.quantity">
														<li>
														<div class="med-bold-font m-auto" [ngClass]="!assignedBay ? 'd-none' : 'w-25'">
															<div *ngIf="assignedBay">
																<span class="pull-right qty-span text-white position-absolute pos-abs-right" [ngClass]="i.qtyloaded == 0 || i.qtyloaded == undefined ? 'bg-danger' : i.qtyloaded < i.quantity ? 'bg-warning': i.qtyloaded == i.quantity ? 'bg-success' : 'bg-danger'">{{i.qtyloaded}}/{{i.quantity}}</span>
															</div>
															<!-- ITEM# -->
														</div>
														<div class="fw-500 xl-bold-font d-flex" [ngClass]="!assignedBay ? 'w-100' : 'w-75'">
															<span class="pull-left w-100" *ngIf="!assignedBay">
																{{i.stkcode}}
																<span class="pull-right qty-span text-white pos-abs-right" [ngClass]="i.qtyloaded == 0 || i.qtyloaded == undefined ? 'bg-danger' : i.qtyloaded < i.quantity ? 'bg-warning': i.qtyloaded == i.quantity ? 'bg-success' : 'bg-danger'" (click)="manual_pick(bay.value, ord, i)">{{i.qtyloaded}}/{{i.quantity}}</span>
																<br>
																<div>
																	<ng-container *ngIf="i.stkcode != 'MST' && i.stkcode && ord.iteminfo[i.stkcode]">
																		<span class="pull-left pl-1 med-normal-font">{{ord.iteminfo[i.stkcode].mfg}}</span>
																	</ng-container>
																	<ng-container *ngIf="i.stkcode == 'MST'">
																		<span class="pull-left pl-1 med-normal-font ">PART#:&nbsp;{{getSuppPartno(i.po_options)}}</span>
																	</ng-container>
																	<br>
																	<span class="pull-left pl-1 med-normal-font">{{i.itemdesc}}</span>
																	<br>
																	<span class="pull-left pl-1 smal-normal-font bin-span align-middle"
																	[ngClass]="i.picked == i.pick ? 'bg-success-light' : 'bg-warning'">
																		{{i.bin}}
																		&nbsp;<mat-icon class="bin-icon text-white align-bottom">inventory_2</mat-icon>&nbsp;
																		<ng-container *ngIf="i.claimed_user != ''">
																			<hr class="p-0 m-0">
																			<span class="claimed">{{i.claimed_user}}</span>
																		</ng-container>
																		<ng-container *ngIf="!i.claimed_user">
																			<hr class="p-0 m-0">
																			{{i.userid}}
																		</ng-container>
																	</span>
																</div>
															</span>

															<span class="pull-left w-100" *ngIf="assignedBay">
																<span class="font-phone-bold"> {{i.stkcode}}</span>
																<div>
																	<ng-container *ngIf="i.stkcode != 'MST' && i.stkcode && ord.iteminfo[i.stkcode]">
																		<span class="pull-left pl-1 font-phone-smaller">{{ord.iteminfo[i.stkcode].mfg}}</span>
																	</ng-container>
																	<ng-container *ngIf="i.stkcode == 'MST'">
																		<span class="pull-left pl-1 med-normal-font ">PART#:&nbsp;{{getSuppPartno(i.po_options)}}</span>
																	</ng-container>
																	<br>
																	<span class="pull-left pl-1 med-normal-font">{{i.itemdesc}}</span>
																	<br>
																	<span class="pull-left pl-1 smal-normal-font bin-span align-middle"
																	[ngClass]="i.picked == i.pick ? 'bg-success-light' : 'bg-warning'">
																		{{i.bin}}
																		&nbsp;<mat-icon class="bin-icon text-white align-bottom">inventory_2</mat-icon>&nbsp;
																		<ng-container *ngIf="i.claimed_user != ''">
																			<hr class="p-0 m-0">
																			<span class="claimed">{{i.claimed_user}}</span>
																		</ng-container>
																		<ng-container *ngIf="!i.claimed_user">
																			<hr class="p-0 m-0">
																			{{i.userid}}
																		</ng-container>
																	</span>
																</div>
															</span>
														</div>
														</li>
													</ng-container>
												</ul>
											</li>
											<!-- loaded but order not fullfilled -->
											<li *ngFor="let i of ord.orderdetails">
												<ul class="m-0 p-0 larger-font display-4 list-unstyled">
													<li>
													<ng-container *ngIf="i.qtyloaded == i.quantity">
														<!-- <div class="spec-label w-25 med-bold-font">ITEM#</div> -->
														<div class="w-100 fw-500 med-bold-font green-full opacity-half">
															<span class="pull-left">{{i.stkcode}}</span>
															<span><mat-icon class="align-middle">local_shipping</mat-icon>&nbsp;{{i.qtyloaded}}/{{i.quantity}}</span>
														</div>
													</ng-container>
													</li>
												</ul>
											</li>
										</ul>
									</div>
								</div>
								<!-- next ups runs off of index of list-->
								<div class="card card-spacing opacity-half" *ngIf="top > 1">
									<div class="card-body row d-flex justify-content-between ml-1 mr-1" [ngClass]="!assignedBay ? 'middle-font' : 'large-font'">
										<div class="d-inline-block font-weight-bolder">#{{ord.header.orderno}}</div>
										<div class="d-inline-block">{{ord.header.brname}}</div>
										<br>
										<!-- <div class="d-inline-block">{{ord.totalqty}}</div> -->
										<hr class="m-0 p-0 w-100">
										<div class="mt-2 w-100 text-left mb-2" *ngFor="let prod of ord['orderdetails']">
											<span class="p-0" [ngClass]="prod.picked == prod.pick ? 'border-success' : 'border-warning'"><b>{{prod['quantity']}}</b>x&nbsp;{{prod['stkcode']}} &nbsp; <b>{{prod.bin}}</b> &nbsp; 
												<ng-container *ngIf="prod.claimed_user"> <span class="claimed">{{prod.claimed_user}}</span></ng-container>
												<ng-container *ngIf="!prod.claimed_user"> {{prod.userid}}</ng-container>
											</span>
										</div>
									</div>
								</div>

							</ng-container>
						</li>
					</ul>
					<!-- fulfilled scan cards -->
					<ng-container *ngIf="bay.value.scans >= bay.value.total_items && bay.value.scans != 0 && bay.value.dispatch.length == 2">
						<mat-form-field appearance="fill" *ngIf="assignedBay && assignedBay != '' && bay.value.removing && showScanInput">
							<mat-label>Input</mat-label>
							<input matInput [formControl]="manualInput" (keyup.enter)="handleInput($event, bay.value)"  #manualInputRef>
						</mat-form-field>
						<div class="rounded raised-zindex" [ngClass]="bay.value.hasoverscan ? 'bg-warning text-black' : 'bg-success text-white'">
							<h4 class="p-2 font-weight-bold display-3 text-center" *ngIf="assignedBay != ''" >RUN LOADED</h4>
							<h4 class="p-2 font-weight-bold display-3 text-center" *ngIf="assignedBay == ''">RUN LOADED</h4>
						</div>
					</ng-container>
					<ul class="m-0 p-0 list-unstyled scrollable" *ngIf="bay.value.dispatch || bay.value.fulfilledOrds">
						<ng-container *ngFor="let ord of bay.value.fulfilledOrds">
							<div class="card card-spacing opacity-half " *ngIf="ord.ordertype != '11'" [ngClass]="ordScanAmount(ord) > calcExpectedScans([ord]) ? 'overscan': 'green-full'">
									<div class="card-body row d-flex justify-content-between large-font ml-1 mr-1" >
										<!-- <div class="col-1 d-inline-block"><mat-icon class="text-success">local_shipping</mat-icon></div> -->
										<div class="col-7 flex-align-center text-left">
											<div class="w-100">
												<div class="d-inline-block font-weight-bolder">#{{ord.header.orderno}}</div>
											</div>
											<div class="w-100">
												<div class="d-inline-block">{{ord.header.brname}}</div>
											</div>
										</div>


										<div class="col-5 flex-align-center text-right ">
											<div class="w-100">
												<div class="d-inline-block text-right font-heavy">
													<ng-container *ngFor="let item of ord.orderdetails">
														{{item.stockid}}<br>
													</ng-container>
												</div>
											</div>
											<div class="w-100 ">
												<div class="d-inline-block text-right"><mat-icon class="align-middle">local_shipping</mat-icon> {{ordScanAmount(ord)}} / {{calcExpectedScans([ord])}} </div>
											</div>
										</div>
									</div>
								</div>
						</ng-container>
					</ul>
					<div *ngIf="!bay.value.dispatch">
						<div class="m-auto">No Dispatch active</div>
					</div>
				</ng-container>
					<ng-container *ngIf="bay.value.completing">
						<div class='col-12 text-center med-bold-font'><h4>Overscans present. Complete anyways?</h4></div>
						<ng-container *ngFor="let ord of bay.value.fulfilledOrds">
							<div class="card card-spacing opacity-half overscan" *ngIf="ord.ordertype != '11' && ordScanAmount(ord) > calcExpectedScans([ord])">
									<div class="card-body row d-flex justify-content-between large-font ml-1 mr-1" >
										<!-- <div class="col-1 d-inline-block"><mat-icon class="text-success">local_shipping</mat-icon></div> -->
										<div class="col-7 flex-align-center text-left">
											<div class="w-100">
												<div class="d-inline-block font-weight-bolder">#{{ord.header.orderno}}</div>
											</div>
											<div class="w-100">
												<div class="d-inline-block">{{ord.header.brname}}</div>
											</div>
										</div>


										<div class="col-5 flex-align-center text-right ">
											<div class="w-100">
												<div class="d-inline-block text-right font-heavy">
													<ng-container *ngFor="let item of ord.orderdetails">
														{{item.stockid}}<br>
													</ng-container>
												</div>
											</div>
											<div class="w-100 ">
												<div class="d-inline-block text-right"><mat-icon class="align-middle">local_shipping</mat-icon> {{ordScanAmount(ord)}} / {{calcExpectedScans([ord])}} </div>
											</div>
										</div>
									</div>
								</div>
						</ng-container>
						<div class="col-12 d-flex text-center" *ngIf="!bay.value.finalizing">
							<div class="col-5">
								<button class="w-100" mat-raised-button color="secondary" (click)="bay.value.completing = false">Go back</button>
							</div>
							<div class="col-2"></div>
							<div class="col-5">
								<button class="w-100" mat-raised-button color="primary" (click)="completeBay(bay.value, true)">Confirm</button>
							</div>
						</div>
					</ng-container>
			</div>
		</ng-container>

		<!-- <button (click)="discover()">discover</button> -->
	</div>
</div>
