<ng-template #detailsModal let-modal>

		<div class="modal-header">
            <h4>{{mfginquiryheader.name}}</h4>
            <h4>Total Sales Amount: {{mfgtotals.sales | currency}}</h4>
            <h4>Total Units: {{mfgtotals.units}}</h4>
		</div>


		<div class="modal-body" *ngIf="mfginquiryready">
            <table mat-table [dataSource]="mfginquiry" class="mat-elevation-z4">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Product Line </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="prods">
                <th mat-header-cell *matHeaderCellDef> # of Products </th>
                <td mat-cell *matCellDef="let element"> {{element.prods}} </td>
            </ng-container>

            <ng-container matColumnDef="units">
                <th mat-header-cell *matHeaderCellDef> Units </th>
                <td mat-cell *matCellDef="let element"> {{element.units}} </td>
            </ng-container>

            <ng-container matColumnDef="sales">
                <th mat-header-cell *matHeaderCellDef> Sales </th>
                <td mat-cell *matCellDef="let element"> {{element.sales | currency}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="scrollToPl(row.id)"></tr>
            </table>
		</div>

		<div class="modal-footer">
            <h5 class="font-weight-lighter ">Date Range: {{getDateRange()}} </h5>
        </div>
	</ng-template>

    <ng-template #exportDialog let-modal>
    <div class="modal-header">
            <h4>Report Export Options</h4>
		</div>
		<div class="modal-body">
            <div class="row">
                <div class="col-md-4"><h4>Include MFG Rollups?</h4></div>
                <div class="col-md-8">
                    <mat-radio-group aria-label="Include Rollup Options" class="d-contents" [(ngModel)]="mfgRollupSelection">
                        <mat-radio-button *ngFor="let op of exportRadioOptions" [value]="op.value">{{op.view}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4"><h4>Breakout Product Lines?</h4></div>
                <div class="col-md-8">
                    <mat-radio-group aria-label="Breakout Productline option" class="d-contents" [(ngModel)]="breakoutSelection">
                        <mat-radio-button *ngFor="let op of exportRadioOptions" [value]="op.value" (click)="limitRollup(op.value)">{{op.view}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4"><h4>Include Product Line Rollups?</h4></div>
                <div class="col-md-8">
                    <mat-radio-group aria-label="Include Rollup Options" class="d-contents" [(ngModel)]="rollupSelection">
                        <mat-radio-button *ngFor="let op of exportRadioOptions" [value]="op.value" [disabled]="breakoutSelection == 2">{{op.view}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="row">
                <hr class="w-100">
            </div>

            <div class="row">
                <div class="col-md-4"><h4 class=" lh-55">Export:</h4></div>
                <div class="col-md-8">
                    <div class="row p-10 justify-content-around ">
                        <!-- <button mat-raised-button (click)="setExportOption('sales')">Sales Only</button> -->
                        <button mat-raised-button (click)="setExportOption('combined')">Export {{exportType}}</button>
                        <!-- <button mat-raised-button (click)="setExportOption('nosales')">No Sales Only</button> -->
                    </div>
                </div>
            </div>
		</div>

	</ng-template>

<div class="main h100 main-content" [ngClass]="config.env.package == 'beauty' ? 'pt35': ''">

    <div class="col-md-auto h100"> <!--filter/ control row-->
        <div class="tree-container" [ngClass]="tree_view ? 'expanded' : 'stowedaway'">
            <app-productlinetree (hideevent)='toggle_tree(false)' (filters)="updatePLSelection($event)"></app-productlinetree>
        </div>
    </div>


    <div class="f-auto h80 bt-border">

        <div id="topnav" class="flex-row topnav" *ngIf="isMobile >= 1500">
            <button mat-raised-button class="btn" *ngIf="!tree_view" (click)="toggle_tree(true)" class="treebtn">Filters</button>
            <mat-form-field color="accent" class="datepicker">
                <mat-label>Enter a date range</mat-label>
                <div class="daterange-div">
                    <mat-date-range-input [rangePicker]="picker" class="daterange-input">
                        <input matStartDate placeholder="Start date" [value]="startdate" (dateInput)="startRangeAltered({start: $event})">
                        <input matEndDate placeholder="End date" [value]="enddate" (dateInput)="endRangeAltered({end: $event})">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
            </mat-form-field>

            <form class="product-filters" *ngIf="config.env.package == 'tires'" [formGroup]="filterForm">
                    <mat-form-field class="season-filter">
                        <mat-label>Season</mat-label>
                        <mat-select formControlName="seasonType" multiple #seasonType>
                            <div class="select-all" (click)="selectAllToggle(seasonType)">
                                <button mat-icon-button>
                                    <span ml="1">Select All/None</span>
                                </button>
                            </div>
                            <mat-option *ngFor="let type of seasons" [value]="type['value']">{{type['viewValue']}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="des-filter">
                        <mat-label>Tire Designation</mat-label>
                        <mat-select formControlName="desType" multiple #desType>
                            <div class="select-all" (click)="selectAllToggle(desType)">
                                <button mat-icon-button>
                                    <span ml="1">Select All/None</span>
                                </button>
                            </div>
                            <mat-option *ngFor="let type of designations" [value]="type['value']">{{type['viewValue']}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="type-filter">
                        <mat-label>Customer Type</mat-label>
                        <mat-select formControlName="customerType" multiple #custType>
                            <div class="select-all" (click)="selectAllToggle(custType)">
                                <button mat-icon-button>
                                    <span ml="1">Select All/None</span>
                                </button>
                            </div>
                            <mat-option *ngFor="let type of cgroups" [value]="type['typeid']">{{type['typename']}}</mat-option>
                        </mat-select>
                    </mat-form-field>
            </form>

            <button mat-raised-button class="btn no-sales-toggle" (click)="toggle_sales(); $event.target.blur(); $event.target.parentNode.blur()" *ngIf="reportdata">
                <ng-container *ngIf="show_zero_sales"><span class="pos"><mat-icon class="lh-21">visibility</mat-icon>$0 PLs</span></ng-container>
                <ng-container *ngIf="!show_zero_sales"><span class="neg"><mat-icon class="lh-21">visibility_off</mat-icon>$0 PLs</span></ng-container>
            </button>

            <div class="slide-container">
                <div class="mtb-auto mr-5">
                    All Skus&nbsp;&nbsp;
                        <mat-slide-toggle color="accent" [formControl]="detailed_toggle"></mat-slide-toggle>
                    &nbsp;&nbsp;Skus W/ Sales
                </div>
                <button *ngIf='!report_running && reportdata != false && !sending' mat-raised-button class="btn btn-danger" (click)="openExportDialog('pdf')"><i class="fa fa-file-pdf-o"></i></button>
                <button *ngIf='!report_running && reportdata != false && !sending' mat-raised-button class="btn btn-success" (click)="openExportDialog('xlsx')"><i class="fa fa-file-excel-o"></i></button>
                 <div class="text-center contents" *ngIf='!report_running && reportdata != false && sending == true'>
                    <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
            </div>

            <div class="f-auto flex-end" *ngIf="active_selections" [ngClass]="report_running ? 'overflow-auto' : ''">
                <div class="table table-responsive text-center flex" *ngIf="report_running">
                    <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
                <ng-continer *ngIf="!report_running" class="flex" style="width: 100px;"><button color="accent" mat-raised-button (click)="runReport()" class="run-btn mtb-auto" [disabled]="active_selections.pls.length == 0">Run Report</button></ng-continer>
            </div>
        </div>

            <div id="topnav" class="flex-row topnav-small" *ngIf="isMobile <= 1499">
                <button mat-raised-button class="btn" *ngIf="!tree_view" (click)="toggle_tree(true)" class="treebtn">Filters</button>
                <mat-form-field color="accent" class="datepicker">
                    <mat-label>Enter a date range</mat-label>
                    <div class="daterange-div">
                        <mat-date-range-input [rangePicker]="picker" class="daterange-input">
                            <input matStartDate placeholder="Start date" [value]="startdate" (dateInput)="startRangeAltered({start: $event})">
                            <input matEndDate placeholder="End date" [value]="enddate" (dateInput)="endRangeAltered({end: $event})">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </div>
                </mat-form-field>

                <form class="product-filters" *ngIf="config.env.package == 'tires'" [formGroup]="filterForm">
                    <div class="flex-column ">
                        <div class="row">
                            <mat-form-field class="season-filter">
                                <mat-label>Season</mat-label>
                                <mat-select formControlName="seasonType" multiple #seasonType>
                                    <div class="select-all" (click)="selectAllToggle(seasonType)">
                                        <button mat-icon-button>
                                            <span ml="1">Select All/None</span>
                                        </button>
                                    </div>
                                    <mat-option *ngFor="let type of seasons" [value]="type['value']">{{type['viewValue']}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="des-filter">
                                <mat-label>Tire Designation</mat-label>
                                <mat-select formControlName="desType" multiple #desType>
                                    <div class="select-all" (click)="selectAllToggle(desType)">
                                        <button mat-icon-button>
                                            <span ml="1">Select All/None</span>
                                        </button>
                                    </div>
                                    <mat-option *ngFor="let type of designations" [value]="type['value']">{{type['viewValue']}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field [ngClass]="tree_view ? 'type-filter ml-0 ' : 'type-filter'">
                                <mat-label>Customer Type</mat-label>
                                <mat-select formControlName="customerType" multiple #custType>
                                    <div class="select-all" (click)="selectAllToggle(custType)">
                                        <button mat-icon-button>
                                            <span ml="1">Select All/None</span>
                                        </button>
                                    </div>
                                    <mat-option *ngFor="let type of cgroups" [value]="type['typeid']">{{type['typename']}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="tree_view">
                                <div class="mtb-auto mr-3">
                                    All&nbsp;&nbsp;
                                        <mat-slide-toggle color="accent" [formControl]="detailed_toggle"></mat-slide-toggle>
                                    &nbsp;&nbsp;W/ Sales
                                </div>
                            </ng-container>
                        </div>

                        <div class="row">

                            <div class="slide-container" *ngIf="!tree_view">
                                <div class="mtb-auto mr-3">
                                    All&nbsp;&nbsp;
                                        <mat-slide-toggle color="accent" [formControl]="detailed_toggle"></mat-slide-toggle>
                                    &nbsp;&nbsp;W/ Sales
                                </div>
                                <button *ngIf='!report_running && reportdata != false && !sending' mat-raised-button class="btn btn-danger expbtn" (click)="openExportDialog('pdf')"><i class="fa fa-file-pdf-o"></i></button>
                                <button *ngIf='!report_running && reportdata != false && !sending' mat-raised-button class="btn btn-success expbtn" (click)="openExportDialog('xlsx')"><i class="fa fa-file-excel-o"></i></button>
                                <div class="text-center contents" *ngIf='!report_running && reportdata != false && sending == true'>
                                    <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </div>
                                <button mat-raised-button class="btn no-sales-toggle expbtn" (click)="toggle_sales(); $event.target.blur(); $event.target.parentNode.blur()" *ngIf="reportdata">
                                    <ng-container *ngIf="show_zero_sales"><span class="pos"><mat-icon class="lh-21">visibility</mat-icon>$0 PLs</span></ng-container>
                                    <ng-container *ngIf="!show_zero_sales"><span class="neg"><mat-icon class="lh-21">visibility_off</mat-icon>$0 PLs</span></ng-container>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>



                <div class="f-auto flex-end" *ngIf="active_selections" [ngClass]="report_running ? 'overflow-auto' : ''">
                            <div *ngIf="config.env.package == 'beauty'" class="slide-container-beauty">
                                <div class="mtb-auto mr-3">
                                    All&nbsp;&nbsp;
                                        <mat-slide-toggle color="accent" [formControl]="detailed_toggle"></mat-slide-toggle>
                                    &nbsp;&nbsp;W/ Sales
                                </div>
                                <button *ngIf='!report_running && reportdata != false && !sending' mat-raised-button class="btn btn-danger " (click)="openExportDialog('pdf')"><i class="fa fa-file-pdf-o"></i></button>
                                <button *ngIf='!report_running && reportdata != false && !sending' mat-raised-button class="btn btn-success" (click)="openExportDialog('xlsx')"><i class="fa fa-file-excel-o"></i></button>
                                <div class="text-center contents" *ngIf='!report_running && reportdata != false && sending == true'>
                                    <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </div>
                                <button mat-raised-button class="btn no-sales-toggle expbtn" (click)="toggle_sales(); $event.target.blur(); $event.target.parentNode.blur()" *ngIf="reportdata">
                                    <ng-container *ngIf="show_zero_sales"><span class="pos"><mat-icon class="lh-21">visibility</mat-icon>$0 PLs</span></ng-container>
                                    <ng-container *ngIf="!show_zero_sales"><span class="neg"><mat-icon class="lh-21">visibility_off</mat-icon>$0 PLs</span></ng-container>
                                </button>
                            </div>
                    <div class="overflow-hidden position-fixed" *ngIf="report_running">
                        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                        </svg>
                    </div>
                    <ng-continer *ngIf="!report_running" class="flex" style="width: 100px;"><button color="accent" mat-raised-button (click)="runReport()" class="run-btn mtb-auto" [disabled]="active_selections.pls.length == 0">Run Report</button></ng-continer>
                    <!-- <ng-container *ngIf="!report_running"><p-splitButton label="Save" icon="pi pi-plus" (onClick)="runReport()" [model]="button_options"></p-splitButton></ng-container> -->
                </div>


            </div>






        <div id="tables-container" class="tables-container" [hidden]="form_control_array.length <= 0">
            <ng-container *ngFor="let mfg of filteredObj | keyvalue">
                <div class="mfg-con">
                    <ng-container *ngFor="let pl of mfg | keyvalue">
                        <ng-container *ngFor="let line of pl.value['pls'] | keyvalue">
                            <div class="flex-row pl-header mat-elevation-z4 header-borders" id="{{line.value['id']}}" style="animation: 200ms;">
                                <h4 class="f33 ws-nowrap">{{line.value['name']}}&nbsp;({{line.value['id']}})</h4>
                                <mat-form-field class="stockid-search"  ngDefaultControl [formControl]="form_control_array[line.value['id']]" (keyup)="filter_pl($event.target.value, mfg.value['name'], line.value['id'])" [ngClass]="datasource_array[line.value['id']].data.length == 0 ? 'hidden' : ''"  *ngIf="isMobile >= 1065">
                                    <mat-label>Stock ID search</mat-label>
                                    <input matInput placeholder="" value="">
                                </mat-form-field>
                                <span class='f33 space-center flex' style='color: rgb(92, 92, 92);'>{{mfg.value['name']}}</span>

                                <h4 class='f33 ws-nowrap'><span style="float: right; color: #757575;">Product Line Total:&nbsp;<span [ngClass]="line.value['sales'] > 0 ? 'pos':'neg'">{{line.value['sales'] | currency}}</span> &nbsp;&nbsp;Units:&nbsp;{{line.value['totalunits']}}</span></h4>
                            </div>

                            <app-productlinetable id="{{line.value['id']}}-tbl" [input]="datasource_array[line.value['id']]" [locs]="locs" class="mt-20"></app-productlinetable>
                            <br id="{{line.value['id']}}-br">
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>

    </div>
    <nav class="navbar fixed-bottom navbar-light bg-light pl-100 flex justify-content-end " *ngIf="viewing_mfgs">
        <mat-chip-list aria-label="MFG viewing" class='chiplist'>
            <div *ngFor="let mfg of viewing_mfgs; let i = index">
                <mat-chip (click)="jump(i); $event.target.blur()" [ngClass]="pageIndex == i ? 'chip-selected': ''">
                    {{mfg}}
                    <ng-container *ngIf="pageIndex == i"><span class="mfgchipinfo">&nbsp;<span class="chipaccent"> Sales: </span>{{this.mfgtotals.sales | currency}} &nbsp; <span class="chipaccent"> Units: </span> {{this.mfgtotals.units}}&nbsp;<mat-icon class="chipicon">visibility</mat-icon></span></ng-container>
                </mat-chip>
            </div>
        </mat-chip-list>
        <mat-paginator
            #paginator
            (page)="pageChangeEvent($event)"
            [length]="totalRecords"
            [pageSize]="pageSize"
            [hidePageSize]="true"
            [pageIndex]="pageIndex"
            [length]="totalRecords"
            [pageSizeOptions]="[1]"
            showFirstLastButtons>
        </mat-paginator>
    </nav>
</div>




<!-- <div #tableSales style="display: hidden;">
    <table class="table table-sm position-fixed w-100">
        <ng-container *ngFor="let mfg of reportdata | keyvalue">
            <ng-container *ngIf="mfgRollupSelection == 1">
                <tr>
                    <th>MFG</th>
                    <th>Total Units</th>
                    <th>Total Sales</th>
                </tr>
                <tr>
                    <td>{{mfg.value['name']}}</td>
                    <td>{{report_totals(mfg.value['name'], 'units')}}</td>
                    <td>{{report_totals(mfg.value['name'], 'sales') | currency}}</td>
                </tr>
            </ng-container>
            <tr *ngIf="breakoutSelection == 2">
                <th>MFG</th>
                <th>Product Line</th>
                <th>Stock ID</th>
                <th>Description</th>
                <th *ngIf="config.env.package == 'tires'">Designation</th>
                <th *ngIf="config.env.package == 'tires'">Season</th>
                <th>Debtorno</th>
                <th *ngIf="config.env.package == 'tires'">Debtor Type</th>
                <th>Name</th>
                <th>Order #</th>
                <th>Order Date</th>
                <th>Location</th>
                <th>Salesman ID</th>
                <th>Salesman Name</th>
                <th>Terms</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Discount</th>
                <th>Total</th>
            </tr>
            <ng-container *ngFor="let pl of mfg | keyvalue">
                <ng-container *ngFor="let line of pl.value['pls'] | keyvalue">
                    <ng-container *ngIf="line.value.totalunits > 0">
                        <ng-container *ngIf="rollupSelection == 1">
                            <tr>
                                <th>Product Line</th>
                                <th>Total Units</th>
                                <th>Total Sales</th>
                            </tr>
                            <tr>
                                <td>{{line.value['name']}}</td>
                                <td>{{line.value['totalunits']}}</td>
                                <td>{{line.value['sales'] | currency}}</td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="breakoutSelection == 1">
                            <th>MFG</th>
                            <th>Product Line</th>
                            <th>Stock ID</th>
                            <th>Description</th>
                            <th *ngIf="config.env.package == 'tires'">Designation</th>
                            <th *ngIf="config.env.package == 'tires'">Season</th>
                            <th>Debtorno</th>
                            <th *ngIf="config.env.package == 'tires'">Debtor Type</th>
                            <th>Name</th>
                            <th>Order #</th>
                            <th>Order Date</th>
                            <th>Location</th>
                            <th>Salesman ID</th>
                            <th>Salesman Name</th>
                            <th>Terms</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th>Discount</th>
                            <th>Total</th>
                        </tr>
                        <ng-container *ngFor="let prod of line.value['prods']">
                            <ng-container *ngFor="let cust of prod['custs']; let i = index">
                                <ng-container *ngFor="let ord of cust['orders']">
                                    <tr>
                                        <td>{{mfg.value['name']}}</td>
                                        <td>{{line.value['id']}}</td>
                                        <td>{{prod['stockid']}}</td>
                                        <td>{{prod['description']}}</td>
                                        <td *ngIf="config.env.package == 'tires'">{{prod['designation']}}</td>
                                        <td *ngIf="config.env.package == 'tires'">{{prod['season']}}</td>
                                        <td><b>{{cust['debtorno']}}</b></td>
                                        <td *ngIf="config.env.package == 'tires'">{{cust['typename']}}</td>
                                        <td>{{cust['name']}}</td>
                                        <td>{{ord['orderno']}}</td>
                                        <td>{{ord['orddate']}}</td>
                                        <td><b>{{ord['fromstkloc']}}</b></td>
                                        <td><b>{{ord['salesperson']}}</b></td>
                                        <td>{{ord['salesmanname']}}</td>
                                        <td>{{ord['payterms']}}</td>
                                        <td>{{ord['unitprice'] | currency}}</td>
                                        <td>{{ord['quantity']}}</td>
                                        <td>{{ord['discountpercent'] | percent}}</td>
                                        <td>{{ord['total'] | currency}}</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <tr *ngIf="breakoutSelection == 1"><td></td></tr>
                    </ng-container>
                </ng-container>
            </ng-container>
            <tr><td></td></tr>
        </ng-container>
    </table>
</div> -->
