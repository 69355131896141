import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl, FormBuilder } from '@angular/forms';


import { OrdersService } from '../../../services/orders.service';
import { CustomerService } from '../../../services/customer.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { PrintService } from '../../../services/print.service';
import { UsersService } from '../../../services/users.service';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PaymentsService } from 'app/services/payments.service';



@Component({
  selector: 'app-adjustment',
  templateUrl: './adjustment.component.html',
  styleUrls: ['./adjustment.component.scss']
})
export class AdjustmentComponent {
	@Input() transaction: any = [];
	@Output() adjusted = new EventEmitter<any>();

	transactionData: any = false;
	adjustData: any = false;
	arAdjustData: any = false;
	glview: any = false;
	displaydetails: any = false;
	newTotal: any = false;
	today: Date = new Date();


	//ar
	ardate = new UntypedFormControl(this.today);
	balance = new UntypedFormControl('');
	amount = new UntypedFormControl('');


	//reverse
	reverse: UntypedFormGroup = this.fb.group({
			date: [this.today, Validators.required],
			reason: ['', Validators.required],
			stock: [true]
		});

	arAdjustType: string;
	adjustAmount: number;
	adjustProcessing: boolean = false;

	constructor( private paymentService: PaymentsService, private usersService: UsersService, private printService: PrintService, private route: ActivatedRoute, public ordersService: OrdersService, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private modalService: NgbModal, private fb: UntypedFormBuilder, private globalsService: GlobalsService) {

	}

	ngOnInit(): void {

		//reverse
		this.customerService.adjustView(this.transaction).subscribe(r=>{
			this.adjustData = r;
		})

		//adjust with ar
		this.customerService.adjustARView(this.transaction).subscribe(r=>{
			this.arAdjustData = r;
			this.newTotal = r.totalamount;
		})

		//view gls
		this.globalsService.getTransactionGl(this.transaction.transno, this.transaction.type).subscribe((result: any) => {
			this.displaydetails = result;
		});
	}



	updateNewTotal(source, event:any){
		this.arAdjustType = 'total';
		this.ardate.setValue('');
		switch(source){
			case 'force':
				this.amount.setValue('');
				this.newTotal = this.financial(event.target.value);
			break;
			case 'adjust':
				this.balance.setValue('');
				this.newTotal = this.financial(Number(this.arAdjustData.totalamount) + Number(event.target.value))
			break;
		}
		this.adjustAmount = this.financial(Number(this.newTotal) - Number(this.arAdjustData.totalamount));

	}

	financial(x){
		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}


	//clear forced balance or adjust amount when posting date is changed
	updatePostingDate(){
		this.arAdjustType = 'date';
		if(this.balance.value){
			this.balance.setValue('');
		}
		if(this.amount.value){
			this.amount.setValue('');
		}
	}


	adjust(type){
		this.adjustProcessing = true;
		var data = {};
		switch (type) {
			case 'reverse':
				data = {
					type: this.adjustData.type,
					id: this.adjustData.id,
					transno: this.adjustData.transno,
					voiddate: this.reverse.get('date').value,
					reason: this.reverse.get('reason').value,
					adjust_stock: this.reverse.get('stock').value
				};
				
				const id = { id: data['id'] }
				switch(this.arAdjustData.type){
					case '10':
						this.paymentService.dealllocateTo(id).subscribe(r => {
							if (r.success) {
								this.adjustReverse(data);
							}
						})
					break;
					case '11':

						this.paymentService.dealllocateFrom(id).subscribe(r => {
							if (r.success) {
								this.adjustReverse(data);
							}
						})
					break;
					case '12':
						if(this.adjustData.totalamount < 0){
						this.paymentService.dealllocateFrom(id).subscribe(r => {
							if (r.success) {
								this.adjustReverse(data);
							}
						})
						} else {
						this.paymentService.dealllocateTo(id).subscribe(r => {
							if (r.success) {
								this.adjustReverse(data);
							}
						})
						}
					break;
				}

			break;
			case 'ar':
				data = {
					type: this.arAdjustData.type,
					id: this.arAdjustData.id,
					transno: this.arAdjustData.transno,
					aradjustvalue: this.adjustAmount,
					adjustpostdate: this.ardate.value ? this.ardate.value : ''

				};
				var dealloc = false;


				if(this.arAdjustData.type == '12' && Number(this.newTotal) > Number(this.arAdjustData.totalamount)){
					dealloc = true;
				}
				if(this.arAdjustData.type == '10' && Number(this.newTotal) < Number(this.arAdjustData.totalamount)){
					dealloc = true;
				}
				if(this.arAdjustData.type == '11' && Number(this.newTotal) !== Number(this.arAdjustData.totalamount)){
					dealloc = true;
				}

				if(dealloc){
					const id = { id: this.arAdjustData.id }

					if (data['type'] == '10') {
						this.paymentService.dealllocateTo(id).subscribe((r) => {
							this.adjustAr(data);
						})
					} else {
						this.paymentService.dealllocateFrom(id).subscribe((r) => {
							this.adjustAr(data);
						})
					}
				} else{
					this.adjustAr(data);
				}
			break;
		}
	}

	adjustAr(data){
		this.customerService.adjustAR(data).subscribe((res) => {
				// show message & close modal & emit reload to trans?
				this.globalSearchService.showNotification(this.arAdjustData.transno + ' successfully adjusted ', 'success', 'bottom', 'left');
				this.adjusted.emit('');
				this.adjustProcessing = false;
			})
	}

	adjustReverse(data){
		this.customerService.adjustReverse(data).subscribe((res) => {
			//show message & close modal & emit reload to trans?
			this.globalSearchService.showNotification(this.adjustData.transno + ' successfully adjusted ', 'success', 'bottom', 'left');
			this.adjusted.emit('');
			this.adjustProcessing = false;
		})
	}


}
