<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">RMA# {{orderdetails.header.orderno}}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>ITEM</th>
						<th>DESC</th>
						<th class="text-center">ORDERED</th>
						<th class="text-center">INVOICED</th>
						<th class="text-center">OUTSTANDING</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">DISCOUNT</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of orderdetails.details">
						<td>{{item.stkcode}}</td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.ordered}}</td>
						<td class="text-center">{{item.qtyinvoiced}}</td>
						<td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
						<td class="text-right">{{item.unitprice | currency}}</td>
						<td class="text-right">{{item.discountpercent | percent }}</td>
						<td class="text-right">{{item.discountprice * item.ordered | currency}}</td>
					</tr>
					<tr *ngFor="let t of orderdetails.saved_totals">
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-right">{{ t.title }}:</td>
						<td class="text-right">{{t.text | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="row m-0 p-0 mt-3">
	<div class="col-md-4">
		<mat-form-field appearance="outline">
			<mat-label>Warehouse</mat-label>
			<mat-select [formControl]="defaultlocation">
				<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
					{{ loc.locationname }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-4">
		<mat-form-field appearance="outline">
			<mat-label>Item Search</mat-label>
			<input matInput [formControl]="searchInputItems" (input)="searchItems()" placeholder="Search..." class="text-right">
			<span matSuffix>
				<button mat-icon-button (click)="searchItems()">
					<mat-icon>search</mat-icon>
				</button>
			</span>
		</mat-form-field>
	</div>
	<div class="col-md-4">
		<mat-form-field appearance="outline">
			<mat-label>Order Search</mat-label>
			<input matInput [formControl]="searchInput" (input)="onInput(searchInput.value)" placeholder="Search..." class="text-right">
			<span matSuffix>
				<button mat-icon-button (click)="search()">
					<mat-icon>search</mat-icon>
				</button>
			</span>
		</mat-form-field>
	</div>
</div>

<div class="table table-responsive text-center" *ngIf="!openorders">
	<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
		</circle>
	</svg>
</div>
<div class="row" *ngIf="!openOrderData">
	<div class="col-md-12">
		<h4 class="mt-3 text-center">No Open RMAs</h4>
	</div>
</div>
<div class="table-container">
	<div class="d-flex justify-content-beginning mb-3">
		<a class="btn bg-success text-white me-2" href="{{returnSheetLink}}&format=xls">
			<i class="fa fa-file-excel-o"></i>
			XLS
		</a>
		<a class="btn bg-danger text-white" href="{{returnSheetLink}}&format=pdf">
			<i class="fa fa-file-pdf-o"></i>
			PDF
		</a>
	</div>
	<table mat-table [dataSource]="openorders" matSort (matSortChange)="announceSortChange($event)" [ngClass]="openOrderData ? '': 'd-none'">

		<ng-container matColumnDef="orderno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> RMA#</th>
			<td mat-cell *matCellDef="let tran">

				<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="viewOrder(tran.orderno, orderDetails)">
						<mat-icon>visibility</mat-icon>
						<span>View</span>
					</button>

					<button mat-menu-item (click)="loadOrder(tran.orderno)">
						<mat-icon>edit</mat-icon>
						<span>Edit</span>
					</button>
					<a href="{{pickinglink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>PDF</span>
					</a>
					<!-- <button mat-menu-item [routerLink]="'/orders/pick/'+tran.orderno">
							<mat-icon>get_app</mat-icon>
							<span>Pick</span>
						</button> -->

					<button mat-menu-item [routerLink]="'/receivables/dispatch/'+tran.orderno">
						<mat-icon>description</mat-icon>
						<span> Credit Account </span>
					</button>
					<!--
						<button mat-menu-item (click)="updatePick(tran.orderno)">
							<mat-icon>done</mat-icon>
							<span>Set Picked</span>
						</button>

						<button mat-menu-item (click)="setDelivered(tran.orderno)">
							<mat-icon>local_shipping</mat-icon>
							<span>Set Delivered</span>
						</button>
						-->
					<button mat-menu-item (click)="cancelOrder(tran.orderno)">
						<mat-icon>remove</mat-icon>
						<span>Cancel</span>
					</button>

				</mat-menu>

				{{ tran.orderno }}

			</td>
		</ng-container>

		<ng-container matColumnDef="statusname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
			<td mat-cell *matCellDef="let tran">
				<span class="status-{{ tran.details.orderstatusid }}">
					{{ tran.statusname }}</span>
				&nbsp;&nbsp;<i class="fa fa-edit" (click)="toggleEdit(tran)" *ngIf="tran.details.orderstatusid !='50'"></i>

				<ng-container *ngIf="tran.edit">
					<mat-form-field appearance="outline">
						<mat-label>Status</mat-label>
						<mat-select [formControl]="order_status_edit">
							<mat-option *ngFor="let s of order_statuses" [value]="s.order_status_id">
								{{ s.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button class="btn btn-sm btn-danger" (click)="saveStatusUpdate(tran)">
						<i class="fa fa-save"></i>
					</button>
				</ng-container>
			</td>
		</ng-container>
		<ng-container matColumnDef="deliverydate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Date Wanted
			<th>
			<td mat-cell *matCellDef="let tran">{{ tran.deliverydate }}</td>
		</ng-container>
		<ng-container matColumnDef="from_order">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice#
			<th>
			<td mat-cell *matCellDef="let tran">{{ tran.from_order }}</td>
		</ng-container>
		<ng-container matColumnDef="branchcode">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Branch Code
			<th>
			<td mat-cell *matCellDef="let tran">{{ tran.branchcode }}</td>
		</ng-container>
		<ng-container matColumnDef="deliverto">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Name
			<th>
			<td mat-cell *matCellDef="let tran">{{ tran.deliverto }}</td>
		</ng-container>
		<ng-container matColumnDef="ponumber">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> PO number#</th>
			<td mat-cell *matCellDef="let tran">{{ tran.ponumber }}</td>
		</ng-container>
		<ng-container matColumnDef="phoneno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Phone#</th>
			<td mat-cell *matCellDef="let tran">{{ tran.phoneno }}</td>
		</ng-container>
		<ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
			<td mat-cell *matCellDef="let tran">{{ tran.email }}</td>
		</ng-container>
		<ng-container matColumnDef="totals">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Total</th>
			<td mat-cell *matCellDef="let tran">{{ getTotal(tran.totals) | currency }}</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;"></tr>
		<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
	</table>
	<mat-paginator [ngClass]="openOrderData ? '': 'd-none'" [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
	</mat-paginator>
</div>
<table *ngIf="openOrderData && !openOrderData.length">
	<tr>
		<td>
			<h4 class="mt-2 text-center">No Open Credits</h4>
		</td>
	</tr>
</table>