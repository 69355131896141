import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { CartProduct, Product } from '../classes/orders';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';


@Injectable({
	providedIn: 'root'
})
export class DispatchService {
	key = 'UET6DFMPAXW7BKCB';
	items: Product[] = [];
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result;
		});
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public addPoint(data: any) {
		const method = 'oms/addcustom';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public claimUserPick(data: any) {
		const method = 'oms/claimpick';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public printPackingList(data: any){
		const method = 'documents/picking/zebra&id='+data;
		return this.http.get(this.setEndPoint(method));
	}

	public getPackingLists(data: any){
		const method = 'oms/picking/getpackinglists';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getOrderStatus(data: any){
		const method = 'oms/picking/getorderstatus&orderno='+data;
		return this.http.get(this.setEndPoint(method));
	}

	public getUserPick(data: any) {
		const method = 'oms/picking/userpicks&userid='+data;
		return this.http.get(this.setEndPoint(method));
	}

	public getDrivers() {
		const method = 'oms/drivers';
		return this.http.get(this.setEndPoint(method));
	}

	public setDrivers(data: any)  {
		const method = 'oms/drivers/assign';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public updateDispatchSort(data: any) {
		const method = 'oms/dispatching/postupdatepositions';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public saveTruck(data: any)  {
		const method = 'oms/trucks/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeTruck(data: any)  {
		const method = 'oms/trucks/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public saveDrivers(data: any)  {
		const method = 'oms/drivers/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeDrivers(data: any)  {
		const method = 'oms/drivers/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public createInvoiceAndPay(data: any) {
		const method = 'oms/invoiceandpay';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getNeedInvoicedAll() {
		const method = 'oms/dispatching/needinvoiced&deliverdonly=false';
		return this.http.get(this.setEndPoint(method));
	}

	public getOrderChanges() {
		const method = 'oms/dispatching/getorderdif';
		return this.http.get(this.setEndPoint(method));
	}

	public closeOrderChange(data: any) {
		const method = 'oms/dispatching/closeorderdif';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getRoutes() {
		const method = 'oms/dispatching/getroutes';
		return this.http.get(this.setEndPoint(method));
	}

	public getPrePaid(run: any) {
		const method = 'oms/dispatching/getprepaid&run='+run;
		return this.http.get(this.setEndPoint(method));
	}

	public getNeedPrePay(run: any) {
		const method = 'oms/dispatching/needprepay&run='+run;
		return this.http.get(this.setEndPoint(method));
	}

	public getNeedInvoiced() {
		const method = 'oms/dispatching/needinvoiced';
		return this.http.get(this.setEndPoint(method));
	}

	public cancelDispatch(id: any) {
		const method = 'oms/dispatching/cancel&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public removeDispatch(id: any) {
		const method = 'oms/dispatching/remove&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public driverRunComplete(data){
		const method = 'oms/dispatching/drivercomplete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public transferDispatch(id: any, to: any) {
		const method = 'oms/dispatching/transfer&id=' + id + '&to=' + to;
		return this.http.get(this.setEndPoint(method));
	}

	public loadTruck(data: any) {
		const method = 'oms/dispatching/loadtruck';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public verifyLoadScan(data: any){
		const method = 'oms/dispatching/verifyloadscan';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getDispNumber(data: any){
		const method = 'oms/dispatching/getdispnumber';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}


	public getBayOrders(data: any){
		const method = 'oms/dispatching/getbayorders';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getBayStatus(){
		const method = 'oms/dispatching/getbaystatus';
		return this.http.get(this.setEndPoint(method));
	}

	public getBayDisps(){
		const method = 'oms/dispatching/getdisps';
		return this.http.get(this.setEndPoint(method));
	}

	public getBayDevice(id){
		const method = 'oms/dispatching/getbaydevice&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public assignDevice(data: any){
		const method = 'oms/dispatching/assigndevice';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method,data))
		);
	}

	public assignBay(data: any){
		const method = 'oms/dispatching/assignbay';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public mailLoadDiscrepancy(data: any){
		const method = 'oms/dispatching/loadingdiscrepancy';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public completeBay(data:any){
		const method = 'oms/dispatching/completebay';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public forceCompleteBay(data:any){
		const method = 'oms/dispatching/forcecompletebay';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public dispIsCleared(data:any){
		const method = 'oms/dispatching/dispclearedcheck';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateBayPriority(data: any){
		const method = 'oms/dispatching/updatebaypriority'
		return this.http.post(this.setEndPoint(method), data).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public truckHasLoads(data: any){
		const method = 'oms/dispatching/truckhasloads';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method,data))
		);
	}

	public orderIsBeingLoaded(data: any){
		const method = 'oms/dispatching/orderontruck';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method,data))
		);
	}

	public deliverRun(id: any) {
		const method = 'oms/dispatching/deliverrun&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public clearRun(id: any) {
		const method = 'oms/dispatching/clearrun&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public deleteRun(data:any) {
		const method = 'oms/dispatching/deleterun';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public moveOms(data: any) {
		const method = 'oms/changetruck';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeOrderFromOms(data: any) {
		const method = 'oms/removeorder';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public clearOmsColumn(id: any) {
		const method = 'oms/clearoms&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public clearRunSaveForLater(id: any) {
		const method = 'oms/dispatching/clearrunsaveforlater&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public returnDispatch(id: any) {
		const method = 'oms/dispatching/return&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public deliverDispatch(id: any) {
		const method = 'oms/dispatching/deliver&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public creditPickedUp(id, amount, orderlineno){
		const method = 'oms/dispatching/creditpickup&id=' + id +'&amt='+amount + '&lineno=' + orderlineno;
		return this.http.get(this.setEndPoint(method));
	}



	public getActivePicked(truck:any, device = '') {
		let method = 'oms/dispatching/getassignedpicks&truckid='+truck;
		if(device != '') {
			method += '&device='+device;
		}

		return this.http.get(this.setEndPoint(method));
	}
	public getActiveRun(truck: any) {
		const method = 'oms/dispatching/getrun&truckid='+truck;
		return this.http.get(this.setEndPoint(method));
	}

	public getColumnCards(column: any) {
		const method = 'oms/getsinglecards&column='+column;
		return this.http.get(this.setEndPoint(method));
	}

	public getTrucks() {
		const method = 'oms/dispatching/trucks';
		return this.http.get(this.setEndPoint(method));
	}


	public getAllTrucks() {
		const method = 'oms/trucks';
		return this.http.get(this.setEndPoint(method));
	}

	public getActiveDispatches() {
		const method = 'oms/dispatching/dispatches';
		return this.http.get(this.setEndPoint(method));
	}

	public getReturnedTrucks(){
		const method = 'oms/dispatching/returnedtrucks';
		return this.http.get(this.setEndPoint(method));

	}

	public refreshOms() {
		const method = 'oms/refreshoms';
		return this.http.get(this.setEndPoint(method));
	}

	public getRecalc(column) {
		const method = 'oms/recalc&column=' + column + this.getKey();
		return this.http.get(this.setEndPoint(method));
	}

	public getDispatchRecalc(column) {
		const method = 'oms/dispatching/recalc&column=' + column + this.getKey();
		return this.http.get(this.setEndPoint(method));
	}

	public getRun(column: any) {
		const method = 'oms/getsinglepreview&column=' + column;
		return this.http.get(this.setEndPoint(method));
	}

	public getRunPick(column: any) {
		const method = 'oms/runpick&column=' + column;
		return this.http.get(this.setEndPoint(method));
	}

	public getPicks() {
		const method = 'oms/dispatching/pick';
		return this.http.get(this.setEndPoint(method));
	}

	public getOrderCards() {
		const method = 'oms/cards';
		return this.http.get(this.setEndPoint(method));
	}

	public getConflictOrders() {
		const method = 'oms/dispatching/conflictorders';
		return this.http.get(this.setEndPoint(method));
	}

	public getOrders() {
		const method = 'oms/dispatching/orders';
		return this.http.get(this.setEndPoint(method));
	}

	public getAllOrders() {
		const method = 'oms/dispatching/orders&picked=false';
		return this.http.get(this.setEndPoint(method));
	}

	public codCheck(data: any) {
		// var method = 'oms/dispatching/codcheck&orderno='+data;
		const method = 'oms/dispatching/codcheck';
		// return this.http.get(this.setEndPoint(method));
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateTimes(data: any) {
		const method = 'oms/updatetimes&column='+data;
		return this.http.get(this.setEndPoint(method));
	}

	public updateDevice(data: any) {
		const method = 'oms/dispatching/updatedevice';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeItemToPlace(data: any) {
		const method = 'oms/dispatching/loadtoplaceremove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public loadItemToPlace(data: any) {
		const method = 'oms/dispatching/loadtoplace';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeItemToTruck(data: any) {
		const method = 'oms/dispatching/loadtotruckremove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public loadItemToTruck(data: any) {
		const method = 'oms/dispatching/loadtotruck';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public unloadItemFromTruck(data: any){
		const method = 'oms/dispatching/unloadfromtruck'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public setPicking(data) {
		const method = 'orders/pick';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}

			return error;
		};
	}

}