import { Component, NgZone, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, ViewEncapsulation, Injector } from '@angular/core';
import { ViewportScroller, Location, DatePipe } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, FormControl } from '@angular/forms';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { PrintService } from '../../services/print.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {Buffer} from 'buffer';
import { scan } from 'rxjs/operators';

@Component({
  selector: 'app-warehouse-checking-wifi',
  templateUrl: './warehouse-checking-wifi.component.html',
  styleUrls: ['./warehouse-checking-wifi.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('300ms ease-out')),
			transition('inactive => active', animate('300ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	],
	// providers: [BluetoothService]
})
export class WarehouseCheckingWifiComponent implements OnInit, AfterViewInit {
	@ViewChild('assignModal') assignModalEle: ElementRef;
	@ViewChild('manualInputRef') manualInputEle: ElementRef;
	device: any;
	batteryLevel: any = '';
	color: any = 'blue';
	user: any = false;
	config: any = false;
	sortcolumns: any = [];
	scantoadd1 = new UntypedFormControl('');
	manualInput = new UntypedFormControl('');
	bay1input = '';
	bays: any = {};
	numOfBays: number = 3;
	alltrucks: any;
	selectedtruck: any;
	activetrucks: any;
	truckisactive: boolean;
	dispatches: any;
	allpoints: any;
	total_order_volume: any;
	total_items: any;
	loadingdata: any;
	loadingrun: any;
	active_dispatches: any;
	active_allpoints: any;
	bayTwoInactive: boolean = false;
	errorDuration: number = 8000;
	assignedBay: any = false;
	apploaded: boolean = false	;
	socketActive: boolean = false;
	showScanInput: boolean = true;

	constructor( public _zone: NgZone, private printService: PrintService, private fb: UntypedFormBuilder, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
	}


	ngAfterViewInit(): void {
		if(this.user.user.userid != 'LOADDOCKTV'){
			//modal for choice
			this.modalService.open(this.assignModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {}, (reason) => { });
			if(this.user.user.user_group != 27){
				this.showScanInput = false;
			}
		}else{
			this.setBays(this.numOfBays);
			this.loadData();
			this.startSocketLoop();
		}
	}

	

	manualDeviceAssign(bay){
		this.assignedBay = bay == '' ? false : bay;
		this.modalService.dismissAll();
		this.startSocketLoop();
		this.setBays(this.numOfBays);
		this.loadData();
	}


	handleInput(event: any, bay:any) {
		const input  = event.target.value
		this.manualInput.setValue('');

		const data = {
			barcode: input,
			device: {
				truckid : bay['truck']['id'],
				bay_id: bay['id'],
				type: 'wifi'
			},
			removing: bay.removing.status,
			user: this.user.user.userid
		}

		const CHAT_ROOM = 'scanners';
		this.omsService.sendloadscan({ data, roomName: CHAT_ROOM }, cb => {});
	}

	ngOnInit(): void {}


	startSocketLoop(){
		if(this.socketActive == false){
			const token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
			if (token) {

				this.omsService.setupSocketConnection(token);
				this.omsService.subscribeToLoadingBays((err, data) => {
					this.loadData();
				});
				this.omsService.subscribeToBayScanResults((err,data: any)=>{
					if(data){
						// data.data = JSON.parse(data.data);
						if(this.assignedBay !== '' || this.assignedBay == data.response.bay_id){
							this.scanlogic(data);
						}
					}
				})
				this.omsService.subscribeToBayScanUpdates((err, data: any)=>{
					if(data){
						data = JSON.parse(data);
						data = data.bay;
						if(this.assignedBay == data.id && !data.type ){
							this.manualDeviceAssign(data.id)
						}
						if(data.type && data.type == 'picking'){
							if(this.bays[data.bay_id].dispatch.length > 0){
								(this.bays[data.bay_id].dispatch).forEach(order => {
									if(order.orderno == data.item.orderno){
										(order.orderdetails).forEach(line => {
											if(line.orderlineno == data.item.linenumber){
												line.picked = data.item.picked;
												line.claimed_user = data.item.claimed_user;
												line.userid = data.user
											}
										});
									}
								});

							}
								
							


						}
					}
				})

				this.socketActive = true;
				setTimeout(() => {
					this.socketActive = false;
					this.omsService.disconnectOnly();
					console.log('Refreshing socket...')
					this.startSocketLoop();
				}, 1000 * 20);
			}
		}
	}

	startSocketListener(){
		if(this.socketActive == false){


			const token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
			if (token) {

				this.omsService.setupSocketConnection(token);
				this.omsService.subscribeToLoadingBays((err, data) => {
					this.loadData();
				});
				this.omsService.subscribeToBayScanResults((err,data: any)=>{
					if(data){
						// data.data = JSON.parse(data.data);
						if(this.assignedBay !== '' || this.assignedBay == data.response.bay_id){
							this.scanlogic(data);
						}
					}
				})
				this.omsService.subscribeToBayScanUpdates((err, data: any)=>{
					if(data){
						data = JSON.parse(data);
						if(this.assignedBay == data.bay.id){
							this.manualDeviceAssign(data.bay.id)
						}
					}
				})

				this.socketActive = true;
			}
		}
	}


	loadData() {
		//get trucks
		this.getTruckData();
		//get highest priority disp per bay
		this.dispatchService.getBayDisps().subscribe((trucks: any) => {

			if(trucks['1'].length == 0){
				this.bays['1'] = this.resetBay(1)
			}
			if(trucks['2'].length == 0){
				this.bays['2'] = this.resetBay(2)
			}
			if(trucks['3'].length == 0){
				this.bays['3'] = this.resetBay(3);
			}

			trucks['data'].forEach(disp => {
				this.dispatchService.getActivePicked(disp.truckid).subscribe((results: any) => {
					//catch for stuck bays due to cleared runs
					if(results == ''){
						this.checkIfRunCleared(disp.dispnumber);
					}else{
						switch (disp.bay_id) {
							case '1':
									this.bays['1'].dispatch = this.hasStartStop(results);
									this.bays['1'].total_items = this.calcExpectedScans(results);
									this.bays['1'].scans = this.calcCompletedScans(results);
									this.bays['1'].truck = this.alltrucks.filter((truck: any) => { return truck.id == disp.truckid })[0];
									this.bays['1'].active = disp.dispnumber;
									this.fulfilledOrders(this.bays['1']);
								break;
							case '2':
									this.bays['2'].dispatch = this.hasStartStop(results);
									this.bays['2'].total_items = this.calcExpectedScans(results);
									this.bays['2'].scans = this.calcCompletedScans(results);
									this.bays['2'].truck = this.alltrucks.filter((truck: any) => { return truck.id == disp.truckid })[0];
									this.bays['2'].active = disp.dispnumber;
									this.fulfilledOrders(this.bays['2']);
								break;
							case '3':
									this.bays['3'].dispatch = this.hasStartStop(results);
									this.bays['3'].total_items = this.calcExpectedScans(results);
									this.bays['3'].scans = this.calcCompletedScans(results);
									this.bays['3'].truck = this.alltrucks.filter((truck: any) => { return truck.id == disp.truckid })[0];
									this.bays['3'].active = disp.dispnumber;
									this.fulfilledOrders(this.bays['3']);
								break;
						}
					}
				});
			});
			// this.updateAllBayDevices();
			this.apploaded = true;
		})
	}

	checkIfRunCleared(disp: any) {
		this.dispatchService.dispIsCleared({'disp': disp}).subscribe((res)=>{
			if(res.status){
				this.dispatchService.completeBay({ 'disp': disp }).subscribe((result) => {
					this.globalSearchService.showNotification(`Dispatch ${disp} has been cleared. Marking loading bay as complete`, 'warning', 'bottom', 'center');
				});
			}
		})
	}

	getTruckData() {
		this.dispatchService.getTrucks().subscribe((result: any) => {
			if (result.length == 1) {
				const truck = result.filter(t => {
					return t.id == result[0].id
				})[0];
			}
			this.alltrucks = result
		});
	}

	setBays(count, handheld = false){
		for (let index = 1; index <= count; index++) {
			this.bays[`${index}`] = this.resetBay(index);
		}
	}

	resetBay(id){
		return {
			'id': `${id}`,
			'completionRate': 0,
			'scans': 0,
			'total_items': 0,
			'dispatch': [],
			'fulfilledOrds': [],
			'truck': [],
			'active': false,
			'removing': {
				'status': false,
				'last': false
			},
			'completing': false,
			'device' : [],
			'error': {
				'status': false,
				'message': ''
			},
			'hasoverscan': false,
			'finalizing': false,
		}
	}

	sendScan(data){



		// this.dispatchService.verifyLoadScan(data).subscribe((res)=>{
		// 	//if head unit (tv) send result of verifyload to socket loadbayscanresult
		// 	//this keeps the phone in sync with the tv
		// 	// if(this.assignedBay == ''){
		// 	// 	const payload = {
		// 	// 		response: res,
		// 	// 		data: data
		// 	// 	};

		// 	// 	const CHAT_ROOM = 'scanners';
		// 	// 	this.omsService.sendloadscanResult({ payload, roomName: CHAT_ROOM }, cb => {});
		// 	// }
		// 	const input = {
		// 		data: {
		// 			response: res,
		// 			data: data
		// 		}
		// 	}
		// 	//sendscan logic moved to new function to be used both by tv & phone
		// 	this.scanlogic(input);
		// })
	}

	scanlogic(input){
		const res = input.response;
		const data = JSON.parse(input.data)

		//keeps remove logic in sync regardless of initiator
		if(data){
			this.bays[res.bay_id].removing.status = data.removing
		}

		if(res.options){return}
			var response = true;
			if(res.success == true){

				//clear error
				this.removeBayError(this.bays[res.bay_id]);

				//if in remove mode
				if(this.bays[res.bay_id].removing.status){
						var filled = (this.bays[res.bay_id].fulfilledOrds).find((dis)=>{
							return res.order.order == dis.order
						})
						if(filled){
							var da = filled;
							var item = (da.orderdetails).find((item)=>{ return item.stockid == res.item.stockid && item.orderlineno == res.item.orderlineno })
						}else{
							var da = (this.bays[res.bay_id].dispatch).find((dis)=>{ return res.order.order == dis.order })
							var item = (da.orderdetails).find((item)=>{ return item.stockid == res.item.stockid && item.orderlineno == res.item.orderlineno})
						}
					this.removePick(this.bays[res.bay_id], da, item);
				}
				// loading
				else{
					var error = false;
					var da = (this.bays[res.bay_id].dispatch).find((dis)=>{ return res.order.order == dis.order })
					if(da){
						var item = (da.orderdetails).find((item)=>{ return item.stockid == res.item.stockid && item.orderlineno == res.item.orderlineno });
						var index =  (this.bays[res.bay_id].dispatch).indexOf(da);

						//if item match is the current active item (index 1) (not index = 0 since thats a dummy stop);
						if(index == 1){
							this.addPick(this.bays[res.bay_id], da, item);
						}else{
							//not active
							error = true;
						}
					}else{
						//not part of da
						error = true;
					}
					if(error){
						const err = {error : 'Scan did not match the active sku'}
						this.handleError(err, this.bays[res.bay_id]);
					}
				}

			//scan couldn't be verified
			}else{
				if(res.function){
					this.handleControl(res.function, this.bays[res.bay_id], data);
				}
				if(res.error){
					this.handleError(res, this.bays[res.bay_id]);
				}
			}
			if(this.assignedBay != ''){
				setTimeout(() => {
					this.manualInputEle.nativeElement.focus();
				}, 1000);
			}
	}

	getActiveSku(bay){
		const loads = bay.dispatch[1].orderdetails;
		var active = false;
		if(loads.length){
			(loads).forEach(line => {
				if(line.qtyloaded != line.quantity){
					if(!active){
						active = line.stockid;
					}
				}
			});
		}
		return active;
	}

	handleControl(control, bay, data){
		//data  = device/barcode input to sendscan
		switch(control){
			case 'remove':
					bay.removing.status = bay.removing.status == false ? true : false;
				break;
			case 'complete':
					this.completeBay(bay);
				break;
			case 'assign_device_1':
					this.assignDevice(data, 1)
				break;
			case 'assign_device_2':
					this.assignDevice(data, 2)
				break;
			case 'assign_device_3':
					this.assignDevice(data, 3)
				break;
			case 'mst':
				data.barcode = 'MST';
				if(this.user.user.userid == data.user){
					this.omsService.sendloadscan({ data, roomName: 'scanners' }, cb => {});
				}else{
					//have to go by user who scanned in data object otherwise doubles up calls
					// alert('user didnt initiate this request');
				}
				break;
		}
	}

	handleError(scanResponse, bay) {
		
		const error = scanResponse.error;
		bay.error.message = error;
		bay.error.status = true;

		//verify that loading bay browser has auotplay detection disabled
		this.audioPlayError(bay, '');

		setTimeout(()=> this.removeBayError(bay), this.errorDuration);
		
		if(scanResponse.barcodeToAdd && this.assignedBay == bay.id){
			const active = this.getActiveSku(bay);
			if(active){
				if(confirm(`Assign ${scanResponse.barcodeToAdd} to ${active} ?`)){
					var data = {
						stockid: active,
						barcode: scanResponse.barcodeToAdd,
						type: 'assign'
					}
					this.inventoryService.addBarcodeClean(data).subscribe((res)=>{
						if(res.success){
							this.globalSearchService.showNotification(`Barcode added to ${active}`, 'success', 'bottom', 'left');
						}
					});
				}
			}
		}

	}

	removeBayError(bay){
		bay.error.message = false;
		bay.error.status = false;
	}

	assignDevice(device, bay_id){
		const data = {
			device: device.device,
			bay_id: bay_id
		}

		this.dispatchService.assignDevice(data).subscribe((res)=>{
			var position = 'left';
			if(bay_id == 2){position = 'center'}
			if(bay_id == 3){position = 'right'}
			if(res.success){
				this.globalSearchService.showNotification(res.message, 'success', 'bottom', position);
				// this.updateAllBayDevices();
			}else{
				this.globalSearchService.showNotification(res.message, 'danger', 'bottom', position);
			}
		})
	}

	updateAllBayDevices(){
		this.dispatchService.getBayDevice(1).subscribe((res: any)=>{
			this.bays['1'].device = res;
		})
		this.dispatchService.getBayDevice(2).subscribe((res: any)=>{
			this.bays['2'].device = res;
		})
		this.dispatchService.getBayDevice(3).subscribe((res: any)=>{
			this.bays['3'].device = res;
		})
	}



	removePick(bay: any, da: any, item: any) {

		if (item.qtyloaded > 0) {
			item.qtyloaded -= 1

			const request = {
				dispatch: da,
				item: item,
				qty: item.qtyloaded,
			}
			if(this.assignedBay == bay.id){
				this.dispatchService.unloadItemFromTruck(request).subscribe(results => {
					if (results.success) {
						this.updateBayProgress(bay);
						bay.removing.last = `-1 x ${item.stockid}`;
						this.removeFromFulfilled(bay);
					}
				})
			}else{
				//risky
				this.updateBayProgress(bay);
				bay.removing.last = `-1 x ${item.stockid} for ${da.orderno}`;
				this.removeFromFulfilled(bay);
			}
		}
	}


	addPick(bay: any, da: any, item: any) {

		if (item.qtyloaded < item.quantity) {
			item.qtyloaded += 1

			const request = {
				dispatch: da,
				item: item,
				qty: item.qtyloaded,
				orderlineno: item.orderlineno
			}

			this.dispatchService.loadItemToTruck(request).subscribe(results => {
				if (results.success) {
					if(da.orderdetails.length > 1){
						let count = da.orderdetails.length
						da.orderdetails.forEach(entry => {
							if(entry.qtyloaded == entry.quantity){
								count--;
								if(count == 0){
									this.manualInput.setValue('');
									const ordIndex = bay.dispatch.indexOf(da);
									const ord = bay.dispatch.splice(ordIndex, 1)[0];
									bay.fulfilledOrds.push(ord);

								}
							}

						});
					}else{
						if (item.qtyloaded == item.quantity) {
							this.manualInput.setValue('');
							const ordIndex = bay.dispatch.indexOf(da);
							const ord = bay.dispatch.splice(ordIndex, 1)[0];
							bay.fulfilledOrds.push(ord);

						}
					}

					this.updateBayProgress(bay);
				}
			})
		}
	}

	//recalculate total scans for bay
	updateBayProgress(bay) {
		bay.scans = this.calcCompletedScans(bay.dispatch) + this.calcCompletedScans(bay.fulfilledOrds);
		if(this.assignedBay != ''){
			this.manualInputEle.nativeElement.focus();
		}
	}


	//remove from fulfilled if qtyloaded < quantity
	removeFromFulfilled(bay) {
		const filled = bay.fulfilledOrds;
		var hasoverscan = false;
		(filled).forEach(da => {
			if (da.ordertype != 0 && da.header.ordertype != 'PU') {
				var rows = da.orderdetails.length;
				(da.orderdetails).forEach(item => {
					if (item.quantity <= item.qtyloaded) {
						rows--;
					}
					if(item.qtyloaded > item.quantity){
						hasoverscan = true;
					}
				});
				if (rows != 0) {
					var ordIndex = filled.indexOf(da);
					if(bay.dispatch.length > 0){
						bay.dispatch.splice(1, 0, filled[ordIndex]);
					}else{
						bay.dispatch.push(filled[ordIndex]);
					}
					bay.fulfilledOrds.splice(ordIndex, 1);
				}
			}
		});

		this.bays[bay.id].hasoverscan = hasoverscan;
	}


	//calc expected total amount for dispatch
	calcExpectedScans(dispatch) {
		return dispatch.reduce((acc, row) => {
			let count = 0;
			if(row.orderdetails){
				row.orderdetails.forEach(item => {
					if(item.categoryid == 'T' && item.stockid != 'FET' && row.ordertype != '11'){
						count += item.quantity;
					}
				});
				return Number(count) + acc;
			}else{
				return acc;
			}
		}, 0);
	}


	//calc completed scans of dispatch
	calcCompletedScans(dispatch) {
		return dispatch.reduce((acc, row) => {
			let scans = 0;
			if (Array.isArray(row.orderdetails) && row.orderdetails.length > 0 && row.ordertype != '11') {
				row.orderdetails.forEach((i) => {
					scans += Number(i.qtyloaded);
				});
			}
			return scans + acc;
		}, 0);
	}


	//completed scans of indvidual orders
	ordScanAmount(ord) {
		return (ord.orderdetails).reduce((acc, row) => {
			return acc += Number(row.qtyloaded);
		}, 0);
	}


	//called on loading of bay - splits completed scans from bay.dispatch and moves to bay.fulfulilled
	fulfilledOrders(bay) {
		if (bay.active) {
			bay.fulfilledOrds = [];

			const dispatch = bay.dispatch;
			var ordsFull = [];
			var creditonly = false;


			(dispatch).forEach(da => {
				if (da.ordertype != 0 && da.ordertype != 11)  {
					var rows = da.orderdetails.length;
					(da.orderdetails).forEach(item => {
						if (item.qtyloaded >= item.quantity && item.categoryid == 'T') {
							if(item.qtyloaded > item.quantity){
								bay.hasoverscan = true;
							}
							rows--;
							if(rows == 0){
								var ordIndex = dispatch.indexOf(da);
								let exist = bay.fulfilledOrds.find((entry)=>{
									return entry.order == da.order
								})
								if(!exist){
									bay.fulfilledOrds.push(dispatch[ordIndex]);
								}
								// console.log(dispatch[ordIndex], bay.fulfilledOrds[bay.fulfilledOrds.indexOf(dispatch[ordIndex])], bay.fulfilledOrds.indexOf(dispatch[ordIndex]))
								ordsFull.push(dispatch[ordIndex]);
							}
						}
					});
				}
				if(da.ordertype == 11){
					if(dispatch.length == 3 && dispatch[0].orderno == "(01) BFDSC" && dispatch[2].orderno == "(01) BFDSC"){
						creditonly = true;
					}
					var ordIndex = dispatch.indexOf(da);
					if(bay.fulfilledOrds.indexOf(dispatch[ordIndex]) < 0){
						bay.fulfilledOrds.push(dispatch[ordIndex]);
					}
					ordsFull.push(dispatch[ordIndex]);
				}
			});

			ordsFull.forEach((ord)=>{
				let orig_da_index = bay.dispatch.indexOf(ord);
				bay.dispatch.splice(orig_da_index, 1);
				// ord['orig_da_index'] = orig_da_index;
			})

			if(creditonly){
				this.completeBay(bay);
			}
		}
	}




	completeBay(bay, overide = false) {

		if(bay.scans < bay.total_items){
			let error = {error: "Dispatch has not been loaded. Complete request ignored."}
			this.handleError(error, bay)
			return;
		}

		if(bay.removing.status){
			this.bays[bay.id].removing.status = false;
		}

		if (bay.hasoverscan && !overide) {
			this.bays[bay.id].completing = true;
			return;
		} else if (bay.hasoverscan && overide) {
			this.bays[bay.id].finalizing = true;
		}

		const bay_const = bay;
		this.dispatchService.completeBay({ 'disp': bay.active }).subscribe((result) => {
			if (result.success) {
				if (bay_const.hasoverscan) {
					const data = this.buildDiscrepancy(bay_const);
					this.dispatchService.mailLoadDiscrepancy(data).subscribe((res) => {
						if (res.success) {
							this.bays[bay_const.id] = this.resetBay(bay_const.id)
							this.loadData();
						}
					})
				} else {
					this.bays[bay_const.id] = this.resetBay(bay_const.id)
					this.loadData();
				}
			}
		})
	}



	audioPlayError(bayi, additional) {
		const bay = bayi
		var audio: any = false;
		var audio_extra:any = false;

		if(bay.id == 1 || bay.id == '1'){
			audio = new Audio('/assets/bay1error.mp3');
		}
		if(bay.id == 2 || bay.id == '2'){
			audio = new Audio('/assets/bay2error.mp3');
		}
		if(bay.id == 3 || bay.id == '3'){
			audio = new Audio('/assets/bay3error.mp3');
		}


		for (let index = 0; index < 3; index++) {
			if(audio){
				setTimeout(()=>{
					if(this.assignedBay == '' || this.assignedBay == bay.id){
						audio.play();
					}
				}, 1700 * index );
			}
		}


		switch(additional){
			case 'noBarcode':
				audio_extra = new Audio('/assets/barcodemissing.mp3');
				break;
			case 'multBarcode':
				audio_extra = new Audio('/assets/barcodemissing.mp3');
				break;
		}

		if(audio_extra){
			setTimeout(()=>{
				audio_extra.play();
			}, 1700);
		}
	}



	//was running into case of start stop points disapperaing after messing with active dispatch - edits/adds/removes (one of those did it but couldn't find where)
	hasStartStop(dispatch){
		var dispatchWithStartStop = [];
		const dummy_stop = {orderdetails:[], ordertype: 0};
		const end = dispatch.length -1;

		if(dispatch[0].ordertype != 0){
			dispatchWithStartStop = [dummy_stop, ...dispatch];
		}

		if(dispatch[end].ordertype != 0){
			dispatchWithStartStop = [...dispatchWithStartStop, dummy_stop];
		}
		return dispatchWithStartStop.length == 0 ? dispatch : dispatchWithStartStop;
	}


	buildDiscrepancy(bay){
		//maybe need truck route
		let table = "<table>";
		let head = "<thead><tr style='background: black; color: white;'>";
		head += "<th>&nbsp;Order#&nbsp;</th>"
		head += "<th>&nbsp;Cust#&nbsp;</th>"
		head += "<th>&nbsp;Name&nbsp;</th>"
		head += "<th>&nbsp;Loaded&nbsp;</th>"
		head += "<th>&nbsp;Ordered&nbsp;</th>"
		head += "<th>&nbsp;Stock ID&nbsp;</th>"
		head += "</tr></thead>";

		let body = "<tbody>"
		let body_end = "</tbody>";
		let table_end = "</table>";

		var lines = []
		if(bay.dispatch){
			(bay.dispatch).forEach((disp)=>{
				if(disp.ordertype != 0){
					let ordTotalQty = disp.totalqty;
					let ordScannedQty = this.ordScanAmount(disp);
					let row;

					if(ordScannedQty > ordTotalQty){
						row = "<tr style='background:#FF4081'>";
					}else{
						row = "<tr>";
					}

					row += "<td>"+disp.header.orderno+"</td>"
					row += "<td>"+ '<a href="https://clevehilltire.com/v8/#/customers/view/' +  disp.header.debtorno + '">'+disp.header.debtorno+'</td>'
					row += "<td>"+disp.header.brname+"</td>"
					row += "<td style='text-align: center'>"+ordScannedQty+"</td>"
					row += "<td style='text-align: center'>"+ordTotalQty+"</td>"
					row += "<td>"+ '<a href="https://clevehilltire.com/v8/#/inventory/view/' + disp.orderdetails[0].stockid + '">'+disp.orderdetails[0].stockid+'</a></td>'
					row += "</tr>";
					body+=row;
				}
			})
		}

		if(bay.fulfilledOrds){
			(bay.fulfilledOrds).forEach((disp)=>{
				if(disp.ordertype != 0){
					let ordTotalQty = disp.totalqty;
					let ordScannedQty = this.ordScanAmount(disp);
					let row;

					if(ordScannedQty > ordTotalQty){
						row = "<tr style='background:#FF4081'>";
					}else{
						row = "<tr>";
					}

					row += "<td>"+disp.header.orderno+"</td>"
					row += "<td>"+ '<a href="https://clevehilltire.com/v8/#/customers/view/' +  disp.header.debtorno + '">'+disp.header.debtorno+'</td>'
					row += "<td>"+disp.header.brname+"</td>"
					row += "<td style='text-align: center'>"+ordScannedQty+"</td>"
					row += "<td style='text-align: center'>"+ordTotalQty+"</td>"
					row += "<td>"+ '<a href="https://clevehilltire.com/v8/#/inventory/view/' + disp.orderdetails[0].stockid + '">'+disp.orderdetails[0].stockid+'</a></td>'

					row += "</tr>";
					body+=row;
				}
			})
		}

		const loads = table+head+body+body_end+table_end +"<br>";

		table = "<table>";
		head = "<thead><tr style='background: black; color:white'>";
		head += "<th>&nbsp;Truck&nbsp;</th>"
		head += "<th>&nbsp;Driver&nbsp;</th>"
		head += "<th>&nbsp;Phone&nbsp;</th>"
		head += "</tr></thead>";

		body = "<tbody><tr>";
		body += "<td class='text-center'>&nbsp;&nbsp;"+bay.truck.name+"&nbsp;&nbsp;</td>";
		body += "<td class='text-center'>&nbsp;&nbsp;"+bay.truck.driver+"&nbsp;&nbsp;</td>";
		body += "<td class='text-center'>&nbsp;&nbsp;"+bay.truck.phone+"</td>&nbsp;&nbsp;</tr>";

		const truck = table+head+body+body_end+table_end + "<br>";

		const res = {
			'html':{
				truck:  Buffer.from(truck.toString(), 'utf8').toString('base64'),
				loads: Buffer.from(loads.toString(), 'utf8').toString('base64')
			},
			'truck' : bay.truck
		}
		return res;
	}



	getSuppPartno(opts){
		return JSON.parse(opts).suppliers_partno
	}

	openBaySelection(){
		this.modalService.open(this.assignModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {}, (reason) => { });
	}


	resetLoads(bay) {
		[...bay.dispatch, ...bay.fulfilledOrds].forEach((ord) => {
			if (ord.orderdetails) {
				ord.orderdetails.forEach(item => {
					while (item.qtyloaded > 0) {
						this.removePick(bay, ord, item);
					}
				});
			}
		})
		this.fulfilledOrders(bay);
	}

	complete_shortcut(bay){
		if(bay.scans == bay.total_items){
			if(confirm(`Complete bay ${bay.id}?`)){
				this.completeBay(bay)
			}
		}
	}

	remove_shortcut(bay){
		const data = {
			barcode: "REMOVE",
			device: {
				truckid : bay['truck']['id'],
				bay_id: bay['id']
			}
		}
		const CHAT_ROOM = 'scanners';
		this.omsService.sendloadscan({ data, roomName: CHAT_ROOM }, cb => {});
	}

	manual_pick(bay, da, item){
		if(this.user.user.userid == 'LOADDOCKTV'){
			this.addPick(bay, da, item);
			const data = {
				in: bay
			}
			this.omsService.sendloadbayupdate({ data, roomName: 'scanners' }, cb => {});
			// 	// }
		}
	}
}