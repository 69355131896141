<div class="main-content" #editRow>
	<div class="card tp-box m-0 p-0">
		<div class="card-header card-header-{{config.color}}-5"> COUNTER POS RECEIPT</div>
		<div class="card-body">
				<!-- <iframe id="ifrm" style="width: 100%; height: 100%;"></iframe> -->
				<ng-container *ngIf="receipt_data">
					<div class="row m-0 p-0s">

						<div class="col-12 ml-auto mr-auto text-center">
							<h1 class="text-success">SUCCESS</h1>
							<h4 class="bold">DOCUMENT#: {{ receipt_data.transno }}</h4>
							<h4 class="bold">INVOICE#: {{ receipt_data.orderno }}</h4>
							<h4 class="bold">CUSTOMER#: {{ receipt_data.header.debtorno }}.{{ receipt_data.header.branchcode }}</h4>
							<h4 class="bold">CUSTOMER: {{ receipt_data.header.deliverto }}</h4>
						</div>
						<div class="col-12 ml-auto mr-auto  text-center">
							<div class="circle-loader load-complete">
								<div class="checkmark-complete draw checkmark"></div>
							</div>
						</div>
						<div class="col-md-4 ml-0 mr-auto  text-center">
							<a mat-flat-button color="accent" class="bg-accent fullsize" (click)="sendPrint()"><div class="pt-4 mt-2">RE-PRINT</div></a>
						</div>
						<div class="col-md-4 ml-auto mr-auto  text-center">
							<a mat-flat-button color="primary" class="bg-primary fullsize" href="{{receipt_data.link}}" ><div class="pt-4 mt-2">DOWNLOAD</div></a>
						</div>

						<div class="col-md-4 text-center ml-auto mr-0  text-center ">
							<button mat-flat-button color="danger" class="bg-danger text-white fullsize m-0 p-0" (click)="$event.preventDefault();newOrder()">NEW ORDER</button>
						</div>


						<div class="col-md-4 ml-0 mr-auto">
							&nbsp;&nbsp;
						</div>
						<div class="col-md-4 mt-3 ml-0 mr-auto  text-center">
							<button mat-flat-button color="success" class="bg-success text-white fullsize ml-auto mr-auto p-0" (click)="$event.preventDefault();emailReceipt()">EMAIL</button>
							
							<div class="row m-0 p-0 justify-content-end">
								<mat-form-field class="mt-2 text-center col-7 ml-0 mr-0 mb-0 p-0" appearance="outline">
									<mat-label>Email</mat-label>
									<span matTextPrefix *ngIf="emailContacts.length > 1">
										<button style="position: absolute; top: 0;" mat-icon-button (click)="cycle('last')">
											<mat-icon>arrow_left</mat-icon>
										</button>
									</span>
									<input matInput [formControl]="email" matInput placeholder="Email Receipt">
									<span matTextSuffix *ngIf="emailContacts.length > 1">
										<button  style="position: absolute; top: 0; right: 0" mat-icon-button (click)="cycle('next')">
											<mat-icon>arrow_right</mat-icon>
										</button>
									</span>
								</mat-form-field>
								<mat-form-field appearance="outline" class="mt-2 col-2 ml-0 mr-0 mb-0 p-0">
									<mat-label>Type</mat-label>
									<mat-select [formControl]="emailopt">
										<mat-option value="invoice">Invoice</mat-option>
										<mat-option value="receipt">Reciept</mat-option>
										<mat-option value="both">Both</mat-option>
									</mat-select>
								</mat-form-field>
								<div class="col-2 ml-1 mr-0 mt-2 mb-0 pl-0 pr-0 pb-0 pt-2" *ngIf="emailContacts">
									<mat-checkbox [(ngModel)]="saveEmail" [disabled]="emailContacts.includes(email.getRawValue())"></mat-checkbox>
									<p>save email?</p>
								</div>
							</div>
							
				




						</div>
						<div class="col-md-4 ml-auto mr-0">
							&nbsp;&nbsp;
						</div>
						<div class="col-md-12 m-0 p-0 text-center bg-white">

							<!-- <iframe #pdfFrame id="pdfFrame" style="display: block;"></iframe> -->
							<!-- <ngx-extended-pdf-viewer [base64Src]="receipt_data.print" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="receipt_data.title"></ngx-extended-pdf-viewer> -->
							<div class="ml-auto mr-auto m-0 p-0 rec ml-auto mr-auto  text-center">
								<div innerHTML="{{ recDisplay }}"></div>
							</div>
						</div>
					</div>
				</ng-container>
		</div>
	</div>
</div>
