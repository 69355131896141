<ng-container *ngIf="reportForm">
	<form [formGroup]="reportForm" >
		<div class="row m-0 p-0 mt-2">
			<div class="col-md-3">
				<mat-form-field appearance="outline">
					<mat-label>Supplier</mat-label>
					<mat-select formControlName="vendorid">
						<mat-option *ngFor="let sup of suppliers" [value]="sup.supplierid">
							{{ sup.supplierid }} - {{ sup.suppname }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-form-field appearance="outline">
					<mat-label>Warehouse</mat-label>
					<mat-select formControlName="loccode">
						<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
							{{ loc.locationname }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<button mat-stroked-button color="warn" (click)="getRecomendations()">Run</button>

				<div class="table table-responsive text-center" *ngIf="loading">
					<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
						</circle>
					</svg>
				</div>
				<div class="table" *ngIf="!loading && results.length">
					<table class="table table-sm">
						<tr>
							<th>Brand</th>
							<th> Item#</th>
							<th> Desc</th>
							<th class="text-center"> QOH </th>
							<th class="text-center"> Back Order </th>
							<th class="text-center"> On Order </th>
							<th class="text-center"> Min </th>
							<th class="text-center"> Max </th>
							<th  class="text-right">Recommended Purchase</th>
						</tr>
						<tr *ngFor="let item of results">
							<td>{{item.brand}}</td>
							<td>{{ item.stockid }}</td>
							<td>{{ item.description }}</td>
							<td class="text-center">{{ item.qop }}</td>
							<td class="text-center">{{ item.qos }}</td>
							<td class="text-center">{{ item.qoh }}</td>
							<td class="text-center">{{ item.min }}</td>
							<td class="text-center">{{ item.max }}</td>
							<td class="text-right">{{ item.qty_purchase }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</form>
</ng-container>
