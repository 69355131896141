<ng-template #assignModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add Products to {{ editcat.name }}</h4>
		<button type="button" mat-icon-button aria-label="Close" (click)="modal.dismiss('Cross click')">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body text-dark">
		<mat-card>
			<mat-card-header>
				<mat-card-title class="text-center">Add Products: Search Criteria</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<form (ngSubmit)="productSearch()">
					<div class="row">
						<div class="col-4">
							<mat-form-field appearance="fill" class="full-width">
								<mat-label>Manufacturer</mat-label>
								<mat-select [formControl]="mfgs" multiple>
									<mat-option *ngFor="let mfg of manus" [value]="mfg.manufacturer_id">
										{{mfg.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-4">
							<mat-form-field appearance="fill" class="full-width">
								<mat-label>Stock Category</mat-label>
								<mat-select [formControl]="cats" multiple>
									<mat-option *ngFor="let cat of stockcats" [value]="cat.categoryid">
										{{cat.categorydescription}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-4">
							<mat-form-field appearance="fill" class="full-width">
								<mat-label>Product Line</mat-label>
								<mat-select [formControl]="pls" multiple>
									<mat-option *ngFor="let pl of prodlines" [value]="pl.line_field">
										{{pl.line_description}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-8">
							<mat-form-field appearance="fill" class="full-width">
								<mat-label>Description Search</mat-label>
								<input matInput type="text" [formControl]="prodsrch" placeholder="Description Search">
							</mat-form-field>
						</div>
						<div class="col-2 d-flex align-items-end">
							<button mat-raised-button color="primary" type="submit" class="full-width">
								Search
							</button>
						</div>

					</div>
				</form>
			</mat-card-content>
		</mat-card>

		<!-- Commenting out the exclusion section -->
		<!--
	<div class="row mt-4">
	  <div class="col-12">
		<h5 class="text-center">Exclusions</h5>
		<div class="scrollable-table">
		  <div *ngIf="exclusions.length != 0">
			<div id="exclusionlist">
			  <mat-list>
				<mat-list-item *ngFor="let item of exclusions" class="list-item" [attr.data-id]="item.sorting" [attr.data-stockid]="item.product_id">
				  <div class="list-content">
					<button mat-icon-button color="warn" (click)="removeExclusion(item)">
					  <mat-icon>delete</mat-icon>
					</button>
					<div class="item-info">
					  <div *ngIf="item.discontinued == 1" class="badge badge-danger">
						<small>&nbsp;</small>
					  </div>
					  <b>{{item.stockid}}</b><br>
					  <small>{{item.description}}</small>
					</div>
					<div class="item-price">
					  <small>{{item.price}} ({{item.qoh}})</small>
					</div>
				  </div>
				</mat-list-item>
			  </mat-list>
			</div>
		  </div>
		  <div class="no-results" *ngIf="exclusions.length == 0">
			No Exclusions
		  </div>
		</div>
	  </div>
	</div>
	-->

		<div class="row mt-4">
			<div class="col-12" *ngIf="resultitems.length">
				<h5 class="text-center">Search Results <div class="col-2 d-flex align-items-end">
						<button mat-icon-button color="warn" type="button" (click)="clearSearch()">
							<mat-icon>clear</mat-icon>
						</button>
					</div>
				</h5>
				<hr>
				<div class="table-responsive">
					<span class="text-success" *ngIf="resultitems.length == 0"><small>Enter Search Criteria</small></span>
					<table *ngIf="resultitems.length != 0" class="table table-bordered table-striped">
						<thead>
							<tr>
								<th class="text-center">Stockid</th>
								<th class="text-center">Description</th>
								<th class="text-center"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of resultitems" id="exlude-{{item.stockid}}">
								<td class="text-center">{{item.stockid}}</td>
								<td class="text-center">{{item.description}}</td>
								<td class="text-center">
									<button mat-stroked-button color="warn" (click)="exclude(item)">Exclude</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="row mt-3">
						<div class="col-12 text-center">
							<button mat-stroked-button color="primary" (click)="saveProducts()">
								<mat-icon>add</mat-icon> Add to Category
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-4">
			<div class="col-12">
				<h4 class="text-center">Current Products</h4>
				<div class="scrollable-table">
					<div *ngIf="items.length != 0">
						<div cdkDropList class="product-drop-list" (cdkDropListDropped)="drop($event)">
							<div *ngFor="let item of items | keyvalue" class="product-drop-box" cdkDrag>
								<button mat-icon-button color="warn" (click)="removeItem(item.key, item.value.stockid)">
									<mat-icon>delete</mat-icon>
								</button>
								<div class="item-info">
									<div *ngIf="item.discontinued == 1" class="badge badge-danger">
										<small>&nbsp;</small>
									</div>
									<b>{{item.value.stockid}}</b><br>
									<small>{{item.value.description}}</small>
								</div>
								<div class="item-price">
									<small>{{item.value.price}} ({{item.value.qoh}})</small>
								</div>
							</div>
						</div>
					</div>
					<div class="no-results" *ngIf="items.length == 0">
						No Results
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-stroked-button color="primary" (click)="updateProducts();modal.dismiss('Cross click')">
			<mat-icon>save</mat-icon>
			Save
		</button>
	</div>
</ng-template>
<ng-template #subCatModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" *ngIf="editcat.name">Add Sub-Category to {{ editcat.name }}</h4>
		<h4 class="modal-title" id="modal-title" *ngIf="!editcat.name">Add Category</h4>
		<button type="button" mat-icon-button aria-label="Close" (click)="modal.dismiss('Cross click')">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-sm-12">
							<mat-form-field appearance="outline">
								<mat-label>Category Name</mat-label>
								<input matInput type="text" [formControl]="catname" />
							</mat-form-field>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-stroked-button color="primary" (click)="addCat();">
			<mat-icon>add</mat-icon>
			Add Category
		</button>
	</div>
</ng-template>
<button mat-stroked-button color="primary" (click)="addMainCat();">
	<mat-icon>add</mat-icon> Category
</button>
<ngx-nestable [(list)]="list" [options]="options" [template]="itemTemplate" (listChange)="changeArray($event)">
</ngx-nestable>

<ng-template #itemTemplate let-row>
	<button mat-icon-button [ngxNestableDragHandle]="row">
		<mat-icon>drag_handle</mat-icon>
	</button>
	<button mat-icon-button *ngIf="row.item.children && row.item.children.length; else empty_item" [ngxNestableExpandCollapse]="row">
		<mat-icon>{{row.item.$$expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
	</button>
	<div class="col-sm-2">
	  <div *ngIf="!row.isEditing">
		{{ row.item.name }}
		<button class="btn btn-link p-0 ml-2" (click)="toggleEdit(row)">
		  <i class="fa fa-pencil"></i>
		</button>
	  </div>
	  <div *ngIf="row.isEditing">
		<input
		  type="text"
		  [(ngModel)]="row.editName"
		  class="form-control form-control-sm d-inline-block w-75"
		/>
		<button
		  class="btn btn-sm btn-success ml-2"
		  (click)="saveEdit(row)"
		>
		  Save
		</button>
		<button
		  class="btn btn-sm btn-secondary ml-2"
		  (click)="cancelEdit(row)"
		>
		  Cancel
		</button>
	  </div>
	</div>


	<div class=" col-sm-2 it-is-your-density">
		<mat-form-field appearance="outline">
			<mat-label>Column</mat-label>
			<input matInput type="text" value="{{row.item.column}}" (change)="changeColumn(row.item.id,$event)" />
		</mat-form-field>
	</div>
	<mat-slide-toggle [checked]="row.item.isallowed == 1" (change)="changeAllowed(row.item.id,$event)">Show</mat-slide-toggle>
	&nbsp;
	<button mat-stroked-button color="primary" (click)="assign(row.item)">
		<mat-icon>edit</mat-icon> Products
	</button>
	<div class="col-sm-2">
		<ngx-dropzone [multiple]="false" (change)="onSelect(row.item.id, $event)" class="drop-zone-wrapper">
			<img src="{{row.item.image}}" class="dropzone-img">
		</ngx-dropzone>
	</div>
	<div class="col-sm-3 pull-right">
		<button mat-stroked-button color="warn" (click)="removeCat(row.item)">
			<mat-icon>delete</mat-icon>
		</button>
		<button mat-stroked-button color="primary" (click)="addSubCat(row.item);">
			<mat-icon>add</mat-icon> Sub Category
		</button>
	</div>
</ng-template>

<ng-template #empty_item>
	<span style="width: 40px"></span>
</ng-template>