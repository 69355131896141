import { Component, SimpleChanges, OnChanges, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../../services/inventory.service';
import { PurchasingService } from '../../services/purchasing.service';
import { OrdersService } from '../../services/orders.service';
import { OmsService } from '../../services/oms.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { FormBuilder, FormGroup, Validators,UntypedFormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { json } from 'express';
import { data } from 'jquery';

@Component({
	selector: 'app-warehouse-scaninout',
	templateUrl: './warehouse-scaninout.component.html',
	styleUrls: ['./warehouse-scaninout.component.scss']
})
export class WarehouseScaninoutComponent implements OnInit, OnChanges {

	@Input() itemdetails: any = false;
	@Input() mstdetails: any = false;
	@Input() vendor_selected: any = false;
	@Input() vendorpos: any = false;
	@Input() vendorrgas: any = false;
	@Input() send_toggle: any;
	@Output() focus_search = new EventEmitter<any>();
	@Output() close_confirm = new EventEmitter<any>();

	@ViewChild('detailsModal') detailsModalEle: ElementRef;

	CHAT_ROOM = 'OrderBoard';
	historyloading = false;
	showorder = false;
	orderdetails: any = [];
	history: any = false;
	color: any = 'blue';
	item: any = false;
	user: any = [];
	location :any ='';
	location_data: any = [];
	recitems: any = [];
	rec_complete = false;
	receiving_quantity = 0;
	receiving_bin: string;
	vendor_pos: any = [];
	last_loaded: any = [];

	scan_history: any = false;
	show_scan_history: any = false;

	expected: any = false;


	qtyRec: UntypedFormControl = new UntypedFormControl(0, Validators.required);
	qtyBin: UntypedFormControl = new UntypedFormControl({value: 'RECDOC', disabled: true}, Validators.required);
	last_success_qtyRec: any;
	last_success_receiving_quantity: number;
	token: any = false;
	already_scanned: any = 0;

	loadingpurchexpected: any = false;
	loadingrgaexpected: any = false;

	constructor(private route: ActivatedRoute,public router: Router,private modalService: NgbModal,private omsService: OmsService,private globalSearchService: GlobalSearchService, private inventoryService: InventoryService, public ordersService: OrdersService, public purchasingService: PurchasingService) {
		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe( (u:any) => {
			this.user = u;
			this.location = u.user.defaultlocation.loccode
		});

		this.globalSearchService.mesagesallowed.next(false);
		this.token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		this.omsService.setupSocketConnection(this.token);

	}

	ngOnInit(): void {

		// alert("1 - (ngOnInit())");

		this.omsService.subscribeToRecScanUpdate((err, data) => {

			this.purchasingService.getRecs('').subscribe((recscanitems: any)  => {
				this.scan_history = recscanitems;
				this.loadData(this.itemdetails.item.stockid)
			})
		});

		this.purchasingService.getRecs('').subscribe((recscanitems: any)  => {
			this.scan_history = recscanitems;
			if(this.itemdetails.item) {
				this.loadData(this.itemdetails.item.stockid);
			}
		})

		if(this.vendorrgas){

		}

		if(this.vendorpos){

		}



	}
	loadData(stockid: any) {

		// alert("2 - (loadData())");

		this.inventoryService.loadItem(stockid).subscribe((result: any) => {
			this.item = result
			this.itemdetails = result

			const search = {
				stockid: stockid,
				vendor: this.vendor_selected
			}

			if(this.loadingpurchexpected) {
				this.loadingpurchexpected.unsubscribe()
			}

			if(stockid === 'MST'){
				const data = {
					orderno: this.mstdetails.orderno
				}
				this.loadingpurchexpected = this.purchasingService.getMstPo(data).subscribe( (results:any) => {
					this.vendorpos = results;
				});
			} else{
				this.loadingpurchexpected = this.purchasingService.getPurchasesForItemByVendor(search).subscribe( (results:any) => {
					this.vendorpos = results;
				});
			}


			if(this.loadingrgaexpected){
				this.loadingrgaexpected.unsubscribe();
			}
			this.loadingrgaexpected = this.purchasingService.getRgasForItemByVendor(search).subscribe( (results:any) => {

				this.vendorrgas = results;
			});

			if(stockid === 'MST'){
				this.recitems = this.vendor_pos;
			}else{
				this.purchasingService.getOpenByItem(stockid).subscribe((recitems:any) => {
					this.recitems= recitems;

				});
			}

			this.inventoryService.getPurchaseHistory(stockid).subscribe(async (result: any) => {
				this.historyloading = false;
				this.history = result
			})

			this.setItemData()
		});
	}

	sendUpdateRec(message: any) {
		const data = {
			message: message
		}
		this.omsService.sendRecScanUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
	}

	toggleHistory(){
		this.show_scan_history == false ? this.show_scan_history = true : this.show_scan_history = false;
	}

	getScanned() {

		if(this.item) {

			const filter_item = (this.item.stockid) ? this.item.stockid: '';
			const scanned = this.scan_history.filter ( (r:any) => {
				if(filter_item === 'MST'){
					return (filter_item == r.stockid && this.mstdetails.orderno == r.ponumber);
				}
				return filter_item == r.stockid
			})[0];
			return scanned;
		}

		return false;
	}

	getTotalScannedIn() {
		const parent = this;
		const totalScan = this.scan_history.reduce(function (accumulator, item) {

			let addon = 0;

			if(parent.itemdetails.item.stockid == item.stockid) {
				addon = parent.sanitizeNumber(item.qty);
			}

		  return accumulator + addon
		}, 0);

		return totalScan;
	}

	setItemData() {

		this.item = this.itemdetails.item
		let pending = 0;
		if(this.expected) {
			if(this.item){
				if(this.item.stockid) {
					if(this.item.stockid === 'MST'){
						// alert(this.expected.pending);
						pending = this.expected.remaining;
					} else{
						pending = this.expected.total_pending
					}
				}
			}
			//set expected value instead of ope pos
			this.itemdetails.openpos = pending;

			// alert("expected: " + JSON.stringify(this.expected));
		}


		const scanned = this.getScanned();

		let reduce = 0;
		if(scanned) {
			reduce = this.sanitizeNumber(scanned.qty)
		}

		this.already_scanned = reduce;
		this.historyloading = true;
		if(this.item) {
			if(this.item.stockid === 'MST'){
				this.receiving_quantity = this.expected - reduce;
			}else{
				this.receiving_quantity = this.itemdetails.openpos - reduce;
			}
		}


		let recvalue = this.receiving_quantity;
		if(this.receiving_quantity < 0) {
			recvalue = 0;
		}

		this.qtyRec.setValue(recvalue);

	}

	increment_scanned(item:any){
		const data = {};
		this.purchasingService.incrementScannned(item).subscribe((updated_item: any)  => {
			this.omsService.sendRecScanUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});

			this.purchasingService.getRecs('').subscribe((recscanitems: any)  => {
				this.scan_history = recscanitems;
			})
		})
	}

	decrement_scanned(item:any){
		const data = {};
		this.purchasingService.decrementScannned(item).subscribe((updated_item: any)  => {
			this.omsService.sendRecScanUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});

			this.purchasingService.getRecs('').subscribe((recscanitems: any)  => {
				this.scan_history = recscanitems;
			})
		})
	}

	setCheckValue(event: any) {
		if(event.checked) {
			this.receiving_quantity = this.itemdetails.openpos;
			this.rec_complete= true;
		} else {
			this.receiving_quantity = 0;
			this.rec_complete= false;
		}

		this.qtyRec.setValue(this.receiving_quantity);

	}


	getItemrecScan() {


	}

	updateBin(value: any) {
		this.receiving_bin = value
	}


	updateQty(value: any, event: any) {

		if(value == ''){
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		this.receiving_quantity = parseFloat(value);
		this.qtyRec.setValue(this.receiving_quantity);
	}

	removePick(event:any) {
		event.stopPropagation();
		event.preventDefault();
		let movement = -1;

		const pre = this.receiving_quantity + movement;
		if(pre >= 0) {
		} else {
			movement = 0
		}

		const recval = this.receiving_quantity + movement;
		this.receiving_quantity = recval;
		this.qtyRec.setValue(this.receiving_quantity);

	}

	addPick(event:any) {
		event.stopPropagation();
		event.preventDefault();
		const movement = 1;
		this.receiving_quantity = this.receiving_quantity + movement;
		this.qtyRec.setValue(this.receiving_quantity);
	}


	updatePutAny() {

		if(this.receiving_quantity) {

			const item = {
				quantityrecd: this.receiving_quantity,
				stockid: this.itemdetails.item.stockid,
				loccode: this.location,
				bin: this.qtyBin.value,
				vendor: this.vendor_selected,
				sending: this.send_toggle ? 1:0
			};

			if(item.stockid == 'MST'){
				item['orderno'] = this.mstdetails.orderno;
			}



			const data = { item, user: this.user.user };
			if (data) {

				this.inventoryService.addRecItem(data).subscribe( r => {
					this.sendUpdateRec(data);
					this.globalSearchService.showNotification(this.receiving_quantity + ' Added', 'success', 'top', 'left')

					this.receiving_quantity = 0;
					this.purchasingService.getRecs('').subscribe((recscanitems: any)  => {
						this.scan_history = recscanitems;
						this.loadData(this.itemdetails.item.stockid)
					})

					this.focus_search.emit({
						searching:true,
					});
				})

			}

		}



	}

	remove_scanned(item:any){
		const opt = confirm(`You are about to remove '${item.stockid}' from the list of scanned items. \n\nWould you like to continue?`);
		if(opt){
			const data = {};
			this.purchasingService.removeScannned(item).subscribe((updated_item: any)  => {
				this.omsService.sendRecScanUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});

				this.purchasingService.getRecs('').subscribe((recscanitems: any)  => {
					this.scan_history = recscanitems;
				})
			})
		}

	}

	loadOrder(order) {
		this.ordersService.getOrder(order).subscribe((results: any) => {
			this.showorder = true;
			this.orderdetails = results;

			this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {

			});

		});
	}

	ngOnChanges(changes: any) {
		if(changes.itemdetails){
			this.itemdetails = changes.itemdetails.currentValue;
			this.item = changes.itemdetails.currentValue;

			if (!changes.itemdetails.firstChange) {
				this.setItemData();
			} else {
				this.purchasingService.getRecs('').subscribe((recscanitems: any)  => {
					this.scan_history = recscanitems;
					this.itemdetails = changes.itemdetails.currentValue;
					this.item = changes.itemdetails.currentValue;
					this.setItemData();
				})
			}
		}

		//  //asn value - something is wrong with vendorpos
		if(changes.vendorpos) {
			 //so not working. need to re-evaluate to a single component instead
			 //calling in seperatly
			if(this.itemdetails.item) {
				const search = {
					stockid: this.itemdetails.item.stockid,
					vendor: this.vendor_selected
				}
				if(this.loadingpurchexpected) {
					this.loadingpurchexpected.unsubscribe()
				}
				if(this.itemdetails.item.stockid === 'MST'){
					const data = {
						orderno: this.mstdetails.orderno
					}
					this.loadingpurchexpected = this.purchasingService.getMstPo(data).subscribe( (results:any) => {
						this.expected = results[0];
						this.setItemData();
					});
				} else {
					this.loadingpurchexpected = this.purchasingService.getPurchasesForItemByVendor(search).subscribe( (results:any) => {
						this.expected = results;
						this.setItemData();
					});
				}
			}
		}
	}

	sanitizeNumber(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(0));
	}


	getDefaultBin(item: any) {
		//item.bins.filter()
	}

	audioPlay() {
		const audio = new Audio('/assets/pristine-609.mp3');
		audio.play();
	}

	audioPlayBad() {
		const audio = new Audio('/assets/glitch.mp3');
		audio.play();
	}

	audioPlayFree() {
		const audio = new Audio('/assets/money.mp3');
		const play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}
}
